import React from "react";

// Customizable Area Start
import {
    Box,
    Stepper,
    Step,
    StepLabel,
    Typography,
    Input,
    Button,
    Switch,
    styled,
    InputAdornment,
    Link
} from "@material-ui/core";
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import { DatePicker, Checkbox } from "antd";

const ConfigJSON = require('./config')
import { logoDark, logoLight, mailIcon, imgPasswordVisible, lock, hiddenVisibility, productCard } from "./assets";
import Loader from "../../../components/src/Loader.web";
import TermsConditions from "../../termsconditions/src/TermsConditions.web";
import { ToastContainer } from "react-toastify";
// Customizable Area End

import EmailAccountRegistrationController, {
  Props,
} from "./EmailAccountRegistrationController";

// Customizable Area Start

const FontStyle = {
    fontFamily: 'Rubik',
    h1: { fontSize: '32px' },
    h2: { fontSize: '30px' },
    h3: { fontSize: '28px' },
    h4: { fontSize: '26px' },
    h5: { fontSize: '24px' },
    h6: { fontSize: '22px' },
    subtitle1: { fontSize: '20px' },
    subtitle2: { fontSize: '18px' },
    body1: { fontSize: '16px' },
    body2: { fontSize: '14px' }
}

export const lightTheme = createTheme({
    palette: {
        primary: {
            main: '#FFFFFF',
            light: '#F8FAFC',
            dark: '#FAFAF9',
            contrastText: '#DC2626'
        },
        secondary: {
            dark: '#808080',
            light: '#848484',
            main: '#000000',
            contrastText: "#94A3B8"
        },
        background: {
            paper: '#EDF1F6',
            default: '#FFFFFF'
        },
        text: {
            hint: '#E2E8F0',
            secondary: '#43536A',
            primary: '#334155',
            disabled: "#F4F4F5"
        },
        action: {
            focus: '#F9F9F9',
            selected: '#1A469C',
            active: '#F1F5F9',
            disabled: '#D7D7D7',
            hover: "#3F3F46",
            disabledBackground: "#27272A"
        },
        info: {
            light: '#475569',
            main: "#0F172A",
            contrastText: '#1A469C',
            dark: '#CBD5E1',
        },
        warning: {
            main: '#E8EDF5',
            light: "#059669",
            dark: '#D97706',
            contrastText: "#DDE1E6"
        },
        error: {
            main: "#FEE2E2",
            light: "#FEF3C7",
            dark: "#D1FAE5",
            contrastText: '#6683BD'
        },
        success: {
            main: "#475569"
        }
    },
    typography: FontStyle
});

export const darkTheme = createTheme({
    palette: {
        primary: {
            main: '#161616',
            light: '#0F172A',
            dark: '#121212',
            contrastText: '#F87171'
        },
        secondary: {
            main: '#FFFFFF',
            dark: '#18212E',
            light: '#99A7BB',
            contrastText: "#94A3B8"
        },
        background: {
            default: '#000000',
            paper: '#121B29'
        },
        text: {
            primary: '#909EB2',
            secondary: '#C4CFE1',
            hint: '#18212E',
            disabled: '#F7FBF7',
        },
        action: {
            active: '#424242',
            selected: '#12326F',
            focus: '#161616',
            disabled: '#D7D7D733',
            hover: "#393D42",
            disabledBackground: "#212529"
        },
        info: {
            main: "#D5DDF0",
            light: '#99A7BB',
            dark: '#273444',
            contrastText: '#70A1FF'
        },
        warning: {
            main: '#121B29',
            light: '#42EBB5',
            dark: "#F28407",
            contrastText: "#242A31"
        },
        error: {
            main: "#1C1C1C",
            light: "#514101",
            dark: "#074525",
            contrastText: "#4F6080"
        },
        success: {
            main: "#93A1B5"
        }
    },
    typography: FontStyle
});
const LoginHeader = styled(Box)({
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    width: '1000px'
})

const HomeLink = styled(Link)(({ theme }) => ({
    fontFamily: 'Rubik',
    fontSize: '24px',
    fontWeight: 700,
    lineHeight: '32px',
    letterSpacing: '-0.005em',
    color: theme.palette.info.contrastText
}))

const MainContainer = styled(Box)(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
    background: theme.palette.background.default,
    height: '100%',
    '& .MuiPaper-root': {
        backgroundColor: theme.palette.primary.main
    }
}))

const LoginContainer = styled(Box)({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
    width: '900px',
})
const UpperRightImg = styled('img')({
    width: '276.49px',
    height: '303.72px',
    gap: '0px',
    opacity: '0.5',
    position: 'absolute',
    top: 0,
    transform: 'rotate(-10deg)',
    alignSelf: 'flex-end'
})

const LowerLeftImg = styled('img')({
    width: '276.49px',
    height: '303.72px',
    gap: '0px',
    opacity: '0.5',
    transform: 'rotate(-193deg)',
    alignSelf: 'flex-start',
    position: 'fixed',
    bottom: -20
})

const LoginCard = styled(Box)(({ theme }) => ({
    boxShadow: '0px 2px 8px 0px #00000014',
    width: '696px',
    padding: '36px 28px 36px 28px',
    gap: '28px',
    borderRadius: '30px',
    background: theme.palette.primary.main,
    zIndex: 1,
    '& .MuiInput-underline::before': {
        borderBottom: 'transparent'
    },
    '& .MuiInput-underline::after': {
        borderBottom: 'transparent'
    },
    '& .MuiInput-underline:hover:not(.Mui-disabled)::before': {
        borderBottom: 'transparent'
    },
    '& .MuiButton-contained:hover': {
        backgroundColor: theme.palette.action.active
    },
    '& .MuiButton-root:hover': {
        backgroundColor: theme.palette.action.active
    },
    "& .nextButtonFirst": {
        '& .MuiButton-contained:hover': {
            backgroundColor: `${theme.palette.action.selected} !important`
        },
        '& .MuiButton-root:hover': {
            backgroundColor: `${theme.palette.action.selected} !important`
        }
    }
}))

const LoginCardFirst = styled(Box)(({ theme }) => ({
    width: '696px',
    boxShadow: '0px 2px 8px 0px #00000014',
    gap: '28px',
    padding: '36px 28px 36px 28px',
    background: theme.palette.primary.main,
    borderRadius: '30px',
    zIndex: 1,
    '& .MuiInput-underline::after': {
        borderBottom: 'transparent'
    },
    '& .MuiInput-underline::before': {
        borderBottom: 'transparent'
    },
    '& .MuiInput-underline:hover:not(.Mui-disabled)::before': {
        borderBottom: 'transparent'
    },
    '& .MuiButton-contained:hover': {
        backgroundColor: theme.palette.action.selected
    },
    '& .MuiButton-root:hover': {
        backgroundColor: theme.palette.action.selected
    },
}))

export const NextButton = styled(Button)(({ theme }) => ({
    gap: '8px',
    borderRadius: '30px',
    padding: '16px',
    backgroundColor: theme.palette.action.selected,
    fontFamily: "Rubik",
    fontSize: '18px',
    fontWeight: 700,
    lineHeight: '26px',
    color: "#F8FAFC",
    marginTop: '15px',
    textTransform: 'capitalize',
    boxShadow: 'none'
}))

export const BackButton = styled(Button)(({ theme }) => ({
    gap: '8px',
    borderRadius: '30px',
    padding: '10px 16px 10px 16px',
    fontFamily: "Rubik",
    fontSize: '18px',
    fontWeight: 500,
    lineHeight: '26px',
    color: theme.palette.info.contrastText,
    marginTop: '15px',
    border: `1px solid ${theme.palette.info.contrastText}`,
    width: '30%',
    backgroundColor: theme.palette.primary.main,
    boxShadow: 'none',
    textTransform: 'capitalize'
}))

export const NextFormButton = styled(Button)(({ theme }) => ({
    gap: '8px',
    borderRadius: '30px',
    padding: '16px',
    backgroundColor: theme.palette.action.active,
    fontFamily: "Rubik",
    fontSize: '18px',
    fontWeight: 700,
    lineHeight: '26px',
    color: theme.palette.text.secondary,
    marginTop: '15px',
    width: '70%',
    boxShadow: 'none',
    marginLeft: '10px',
    textTransform: 'capitalize'
}))

const EmailTextField = styled(Input)(({ theme }) => ({
    width: '100%',
    gap: '8px',
    borderRadius: '8px',
    padding: '16px',
    border: `2px solid ${theme.palette.info.contrastText}`,
    fontFamily: 'Rubik',
    fontSize: '16px',
    fontWeight: 500,
    lineHeight: '24px',
    textAlign: 'left',
    color: theme.palette.secondary.main,
    background: theme.palette.primary.light,
    marginTop: '5px',
    '& .MuiInput-underline:hover:not(.Mui-disabled)::before': {
        borderBottom: 'transparent'
    },
    '& .MuiInput-underline::after': {
        borderBottom: 'transparent'
    }
}))

const RoleTextField = styled(Input)(({ theme }) => ({
    marginTop: '5px',
    borderRadius: '8px',
    padding: '16px',
    border: `2px solid ${theme.palette.info.contrastText}`,
    fontFamily: 'Rubik',
    fontSize: '16px',
    fontWeight: 500,
    lineHeight: '24px',
    textAlign: 'center',
    background: theme.palette.primary.light,
    '& .MuiInput-underline:hover:not(.Mui-disabled)::before': {
        borderBottom: 'transparent'
    },
    '& .MuiInputBase-input': {
        textTransform: 'capitalize',
        textAlign: 'center'
    },
    '& .MuiInputBase-input.Mui-disabled': {
        color: theme.palette.info.contrastText
    },
}))

const NameDateSection = styled(Box)({
    display: 'flex',
    flexDirection: 'row',
    "& .MuiInput-underline::before": {
        borderBottom: 'transparent'
    },
    "& .Styled\(MuiBox\)-root-41 .MuiInput-underline::before": {
        borderBottom: 'transparent'
    },
    "& .MuiInput-underline::after": {
        borderBottom: 'transparent'
    }
})
const NameTextField = styled(Input)(({ theme }) => ({
    padding: '10px 16px 10px 16px',
    gap: '8px',
    borderRadius: '8px',
    color: theme.palette.secondary.main,
    border: `1px solid ${theme.palette.info.dark}`,
    marginTop: '5px',
    background: theme.palette.background.default,
    '& .MuiInput-underline:hover:not(.Mui-disabled)::before': {
        borderBottom: 'transparent !important'
    },
    '& .MuiInput-underline::before': {
        borderBottom: 'transparent !important'
    },
    '& .MuiInput-underline::after': {
        borderBottom: 'transparent !important'
    }
}))

const DateField = styled(DatePicker)(({ theme }) => ({
    marginTop: '5px',
    background: theme.palette.background.default,
    border: `1px solid ${theme.palette.info.dark}`,
    width: '100%',
    padding: '10px 16px',
    borderRadius: '8px',
    height: '54.4px',
    fontFamily: 'Rubik',
    fontSize: '16px',
    fontWeight: 500,
    lineHeight: '24px',
    textAlign: 'left',
    '& .ant-picker-input > input': {
        color: theme.palette.secondary.main
    },
    '& .ant-picker-suffix': {
        display: 'none'
    },
    '& .ant-picker-input:hover .ant-picker-clear': {
        opacity: 1,
        display: 'none !important'
    },
    '& .ant-picker-focused': {
        boxShadow: 'none'
    },
    '& .ant-picker-input > input::placeholder': {
        color: theme.palette.info.light,
        fontFamily: 'Rubik',
        fontSize: '16px',
        fontWeight: 400,
        lineHeight: '24px'
    }
}))

const EmailLabel = styled(Typography)(({ theme }) => ({
    fontFamily: 'Rubik',
    fontSize: '16px',
    fontWeight: 400,
    lineHeight: '24px',
    textAlign: 'left',
    color: theme.palette.text.primary
}))

const NameLabel = styled(Typography)(({ theme }) => ({
    fontFamily: 'Rubik',
    fontSize: '14px',
    fontWeight: 400,
    lineHeight: '22px',
    textAlign: 'left',
    color: theme.palette.text.primary
}))

const Steppers = styled(Stepper)(({ theme }) => ({
    margin: '20px auto',
    padding: '15px',
    "& .MuiStepper-root": {
        background: theme.palette.primary.main,
        width: '650px',
    },
    '& .MuiStepper-alternativeLabel': {
        width: '600px !important'
    },
    '& .MuiStepIcon-text': {
        fontFamily: 'Rubik',
        fontSize: '16px',
        fontWeight: 700,
        lineHeight: '24px',
        fill: '#FFF'
    },
    '& .MuiStepLabel-label.MuiStepLabel-alternativeLabel': {
        fontFamily: 'Rubik',
        fontSize: '14px',
        lineHeight: '22px'
    },
    '& .MuiStepLabel-label.MuiStepLabel-active': {
        color: theme.palette.info.contrastText,
        fontWeight: 500
    },
    '& .MuiStepIcon-root.MuiStepIcon-completed': {
        color: theme.palette.info.contrastText,
        fontWeight: 500
    },
    '& .MuiStepLabel-label': {
        color: '#96AAD1',
        fontWeight: 400
    },
    '& .MuiStepIcon-root': {
        color: '#96AAD1'
    },
    '& .MuiStepIcon-root.MuiStepIcon-active': {
        color: theme.palette.info.contrastText
    },
    '& .MuiStepLabel-label.MuiStepLabel-completed': {
        color: `${theme.palette.info.contrastText} !important`
    },
    '& .MuiStepConnector-line': {
        borderColor: '#94A3B8'
    }
}))

const LoginLinkDiv = styled(Box)({
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    marginTop: '20px'
})

const LoginStatement = styled(Typography)({
    fontFamily: 'Rubik',
    fontSize: 16,
    fontWeight: 400,
    textAlign: 'center',
    // color: '#334155'
})

const LoginLink = styled('span')(({ theme }) => ({
    color: theme.palette.info.contrastText,
    cursor: 'pointer',
    fontFamily: 'Rubik',
    fontSize: '16px',
    fontWeight: 700,
    lineHeight: '24px',
    textDecoration: 'underline'
}))

const LoginHeading = styled(Typography)({
    letterSpacing: '-0.005em',
    fontFamily: 'Poppins',
    fontSize: 24,
    fontWeight: 700,
    textAlign: 'center',
    lineHeight: '32px'
})

const SwitchDiv = styled(Box)(({ theme }) => ({
    display: 'flex',
    flexDirection: 'row',
    marginTop: '20px',
    "& p": {
        marginTop: '5px',
        color: theme.palette.secondary.main
    },
    '& .MuiSwitch-root': {
        padding: '10px'
    },
    '& .MuiSwitch-colorSecondary.Mui-checked + .MuiSwitch-track': {
        backgroundColor: theme.palette.text.hint
    }
}))

export const TermsChecked = styled(Checkbox)(({ theme }) => ({
    "& .ant-checkbox-inner": {
        borderRadius: '6px',
        height: '20px',
        width: '20px',
        top: 1,
        border: `1px solid ${theme.palette.info.main}`,
        background: theme.palette.primary.main
    },
    '& .ant-checkbox-input:hover': {
        borderRadius: '6px',
        borderColor: `${theme.palette.info.main}} !important`
    },
    '& .ant-checkbox-checked .ant-checkbox-inner::after': {
        borderColor: theme.palette.secondary.main
    }

}))

export const PasswordChecked = styled(Checkbox)(({ theme }) => ({
    height: '18px',
    width: '18px',
    borderRadius: '50px',
    marginRight: '5px',
    "& .ant-checkbox-inner": {
        borderRadius: '50px',
        backgroundColor: 'transparent',
        borderColor: 'transparent'
    },
    "& .ant-checkbox": {
        top: 0
    },
    '& .ant-checkbox-checked .ant-checkbox-inner::after': {
        borderColor: theme.palette.primary.main
    }
}))

export const CheckText = styled('span')(({ theme }) => ({
    color: theme.palette.info.main,
    fontFamily: 'Poppins',
    fontSize: '16px',
    fontWeight: 400,
    lineHeight: '24px',
    marginLeft: '5px'
}))

export const PasswordText = styled('span')(({ theme }) => ({
    color: theme.palette.secondary.main,
    fontFamily: 'Rubik',
    fontSize: '14px',
    fontWeight: 400,
    lineHeight: '22px'
}))

export const ValidationSection = styled(Box)({
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-evenly',
    padding: '8px'
})
// Customizable Area End

export default class EmailAccountRegistration extends EmailAccountRegistrationController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
}
 // Customizable Area Start
    loginLink = () => (
        <LoginLink data-test-id="LoginLink" onClick={this.gotoLogin}>&nbsp;{ConfigJSON.loginText}</LoginLink>
    )
    emailSection = () => (
        <LoginCardFirst>
            <LoginHeading color="secondary">
                {ConfigJSON.loginHeading}
            </LoginHeading>
            <LoginLinkDiv>
                <LoginStatement color="secondary">{'Already have an account?'}</LoginStatement>
                {this.loginLink()}
            </LoginLinkDiv>
            <Steppers activeStep={0} alternativeLabel>
                {ConfigJSON.steps.map((label: string) => (
                    <Step key={label}>
                        <StepLabel>{label}</StepLabel>
                    </Step>
                ))}
            </Steppers>
            <EmailLabel>What’s your email?</EmailLabel>
            <EmailTextField
                data-test-id='email'
                type="email"
                onChange={this.changeEmail}
                value={this.state.email}
                startAdornment={
                    <InputAdornment position="start">
                        <img src={mailIcon} />
                    </InputAdornment>
                }
            />
            <NextButton
                data-test-id='emailNext'
                variant="contained"
                fullWidth
                onClick={this.gotoDetailSection}
                style={{ color: '#F8FAFC' }}
            >
                Next
            </NextButton>
        </LoginCardFirst>
    )

    detailSection = () => (
        <LoginCard>
            <LoginHeading color="secondary">
                Let’s Setting up your account
            </LoginHeading>
            <LoginLinkDiv>
                <LoginStatement color="secondary">{ConfigJSON.loginStatement}</LoginStatement>
                {this.loginLink()}
            </LoginLinkDiv>
            <Steppers activeStep={1} alternativeLabel>
                {ConfigJSON.steps.map((label: string) => (
                    <Step key={label}>
                        <StepLabel>{label}</StepLabel>
                    </Step>
                ))}
            </Steppers>
            <NameLabel>Preferred Name</NameLabel>
            <NameTextField
                data-test-id='name'
                placeholder="Enter your preferred name"
                fullWidth
                required
                onKeyPress={this.acceptAlphabet}
                onChange={this.changeName}
                value={this.state.firstName}
            />
            <NameDateSection style={{ margin: "10px 0px" }}>
                <div style={{ width: '50%' }}>
                    <NameLabel>Phone Number</NameLabel>
                    <NameTextField
                        data-test-id='phoneNumber'
                        placeholder="Enter phone number with country code"
                        fullWidth
                        required
                        onChange={this.changePhone}
                        value={this.state.phone}
                        onKeyPress={this.acceptNumber}
                        inputProps={{
                            maxLength: 15,
                        }}
                    />
                </div>
                <div style={{ marginLeft: '15px', width: '50%' }}>
                    <NameLabel>Date of birth</NameLabel>
                    <DateField
                        placement="bottomRight"
                        format="MM/DD/YY"
                        showToday={false}
                        placeholder="mm/dd/yy"
                        onChange={this.changeDOB}
                        data-test-id='date'
                        value={this.state.dateOfBirth}
                        disabledDate={this.disableFutureDate}
                    />
                </div>
            </NameDateSection>
            <NameLabel>Role</NameLabel>
            <RoleTextField
                fullWidth
                disabled
                value={this.state.role}
            />
            <NameDateSection>
                <BackButton
                    data-test-id='backButton'
                    variant="contained"
                    onClick={this.gotoEmailSection}
                >
                    Back
                </BackButton>
                <NextFormButton
                    data-test-id='nextButton'
                    variant="contained"
                    onClick={this.gotoPasswordSection}
                >
                    Next
                </NextFormButton>
            </NameDateSection>
        </LoginCard>
    )

    NewPasswordSection = () => (
        <LoginCard>
            <LoginHeading color="secondary">
                New Password
            </LoginHeading>
            <LoginLinkDiv>
                <LoginStatement color="secondary">{ConfigJSON.loginStatement}</LoginStatement>
                {this.loginLink()}
            </LoginLinkDiv>
            <Steppers activeStep={2} alternativeLabel>
                {ConfigJSON.steps.map((label: string) => (
                    <Step key={label}>
                        <StepLabel>{label}</StepLabel>
                    </Step>
                ))}
            </Steppers>
            <NameLabel>Password</NameLabel>
            <NameTextField
                data-test-id='password'
                type={this.state.showPassword ? 'text' : 'password'}
                placeholder="Enter your password"
                fullWidth
                startAdornment={
                    <InputAdornment position="start">
                        <img src={lock} />
                    </InputAdornment>
                }
                endAdornment={
                    <InputAdornment position="start">
                        <img
                            data-test-id='showPassword'
                            style={{ cursor: 'pointer', height: '24px', width: '24px' }}
                            src={this.state.showPassword ? imgPasswordVisible : hiddenVisibility}
                            onClick={() => this.setState({ showPassword: !this.state.showPassword })}
                        />
                    </InputAdornment>
                }
                onChange={this.changePassword}
                value={this.state.password}
            />
            <NameLabel style={{ marginTop: '5px' }}>Confirm Password</NameLabel>
            <NameTextField
                data-test-id='confirmPassword'
                type={this.state.ShowConfirmPassword ? 'text' : 'password'}
                placeholder="Enter your confirm password"
                fullWidth
                startAdornment={
                    <InputAdornment position="start">
                        <img src={lock} />
                    </InputAdornment>
                }
                endAdornment={
                    <InputAdornment position="start">
                        <img
                            data-test-id='ShowConfirmPassword'
                            style={{ cursor: 'pointer', height: '24px', width: '24px' }}
                            src={this.state.ShowConfirmPassword ? imgPasswordVisible : hiddenVisibility}
                            onClick={() => this.setState({ ShowConfirmPassword: !this.state.ShowConfirmPassword })}
                        />
                    </InputAdornment>
                }
                onChange={this.changeConfirmPassword}
                value={this.state.reTypePassword}
            />
            {!(this.state.containCharLength && this.state.containNumber && this.state.containLower && this.state.containSpecial && this.state.containUpper) &&
                <div style={{ margin: '15px' }}>
                    <ValidationSection>
                        <div>
                            <PasswordChecked defaultChecked style={{ backgroundColor: this.state.containCharLength ? '#1A469C' : '#B8C6E0' }} />
                            <PasswordText>Use 8 or more characters</PasswordText>
                        </div>
                        <div>
                            <PasswordChecked defaultChecked style={{ backgroundColor: this.state.containNumber ? '#1A469C' : '#B8C6E0' }} />
                            <PasswordText>One number</PasswordText>
                        </div>
                        <div>
                            <PasswordChecked defaultChecked style={{ backgroundColor: this.state.containLower ? '#1A469C' : '#B8C6E0' }} />
                            <PasswordText>One lowercase character</PasswordText>
                        </div>
                    </ValidationSection>
                    <ValidationSection>
                        <div>
                            <PasswordChecked defaultChecked style={{ backgroundColor: this.state.containSpecial ? '#1A469C' : '#B8C6E0' }} />
                            <PasswordText>One special character</PasswordText>
                        </div>
                        <div>
                            <PasswordChecked defaultChecked style={{ backgroundColor: this.state.containUpper ? '#1A469C' : '#B8C6E0' }} />
                            <PasswordText>One Uppercase character</PasswordText>
                        </div>
                    </ValidationSection>
                </div>}
            <ValidationSection>
                <div>
                    <TermsChecked
                        data-test-id='termsCheck'
                        onChange={(event) => this.changeTerms(event.target.checked)}
                    />
                    <CheckText>By creating an account, I agree to our </CheckText>
                    <LoginLink data-test-id="openTermsModal" onClick={this.openTermsModal}>Terms and conditions</LoginLink>
                </div>
            </ValidationSection>
            {this.state.showTermsSelect && <Typography style={{ color: '#B00606', textAlign: 'center' }}>You have to agree with Terms and Conditions to Sign up</Typography>}
            <NameDateSection>
                <BackButton
                    data-test-id='detailsBackButton'
                    variant="contained"
                    onClick={this.gotoDetailSection}
                >
                    Back
                </BackButton>
                <NextFormButton
                    data-test-id='detailsNextButton'
                    variant="contained"
                    onClick={this.gotoFinalStep}
                >
                    Finish
                </NextFormButton>
            </NameDateSection>
        </LoginCard >
    )

    successMsgSection = () => (
        <LoginCard style={{ marginTop: '100px' }}>
            <LoginHeading color="secondary">
                Welcome to PetroPal, {this.state.userName} !
            </LoginHeading>
            <LoginLinkDiv>
                <LoginStatement color="secondary">{ConfigJSON.successMessage}</LoginStatement>
            </LoginLinkDiv>
        </LoginCard >
    )
 // Customizable Area End
  render() {
    // Customizable Area Start
    // Customizable Area End
    return (
      // Customizable Area Start
        // Required for all blocks
            <ThemeProvider theme={this.state.darkMode ? darkTheme : lightTheme}>
                {this.state.loading ? <Loader loading={this.state.loading} /> :
                    <MainContainer>
                        <LoginHeader>
                            <img src={this.state.darkMode ? logoLight : logoDark} alt="logo" />
                            <HomeLink href="/" >HOME</HomeLink>
                        </LoginHeader>
                        <LoginContainer>
                            <UpperRightImg src={productCard} />
                            {this.state.enableEmailSection && this.emailSection()}
                            {this.state.enableDetailSection && this.detailSection()}
                            {this.state.enablePasswordSection && this.NewPasswordSection()}
                            {this.state.enableSuccessSection && this.successMsgSection()}
                            <LowerLeftImg src={productCard} />
                        </LoginContainer>
                        <SwitchDiv>
                            <Switch data-test-id="toggleTheme" onChange={this.toggleMode} checked={this.state.darkMode} />
                            <Typography>Dark Theme</Typography>
                        </SwitchDiv>
                    </MainContainer>}
                <TermsConditions
                    navigation={undefined}
                    id={""}
                    openTermsModal={this.state.termsModal}
                    closeTermsModal={this.closeTermsModal.bind(this)}
                />
                <ToastContainer />
            </ThemeProvider>
       // Customizable Area End
    );
  }
}
