import React from "react";

// Customizable Area Start
import {
  Box,
  Button,
  Typography,
  IconButton,
  Grid,
  Menu,
  MenuItem,
  CircularProgress,
  ButtonGroup,
  Avatar,
  TextField,
  Divider,
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from "@material-ui/core";
import { styled, ThemeProvider } from "@material-ui/core/styles";
import { ExpandMore, ExpandLess, } from "@material-ui/icons";
import { ITask, ISubTask } from "./types";
import { FieldModal, ModalHeading, ModalLine, ModalSubHead } from "../../customisableuserprofiles/src/CustomisableUserProfiles.web";
import AddBoxOutlinedIcon from "@material-ui/icons/AddBoxOutlined";
import MoreVertOutlinedIcon from "@material-ui/icons/MoreVertOutlined"
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import HamburgerMenu from "../../hamburgermenu/src/HamburgerMenu.web";
import { BackButton, NextButton, darkTheme, lightTheme } from "../../email-account-registration/src/EmailAccountRegistration.web";
import { DesignationField, DotSection, NameField } from "../../notifications/src/Notifications.web";
import { Checkbox } from "antd";
import { InputField } from "../../settings2/src/Settings2.web";
import { deleteIcon, editButton, expand, reduce } from './assets'
import { InProgressLabel, AssignedLabel, CompletedLabel } from "../../certificationtracking/src/CertificationTracking.web";
import { ToastContainer } from "react-toastify";
import moment from "moment";
import { Bar, BarChart, CartesianGrid, XAxis, YAxis } from "recharts";
import Loader from "../../../components/src/Loader.web";
// Customizable Area End

import TaskController, { Props, configJSON } from "./TaskController";

// Customizable Area Start
// Customizable Area End

export default class Task extends TaskController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  renderField = (item: ISubTask, index: number) => (
    <div style={{ display: "flex", flexDirection: "row" }}>
      <SubTaskText
        data-test-id="txtSubTask"
        placeholder="Type here your task Name"
        onChange={(event) => this.changeSubTask(event.target.value, index)}
        value={this.state.subTask[index].name}
      />
      <img data-test-id="removeTextField" src={deleteIcon} onClick={() => this.removeField(index, item)} />
    </div>
  )

  AddTaskModal = () => (
    <FieldModal
      data-test-id='replyModal'
      open={this.state.isVisibleModal}
      onCancel={this.showAddModal}
      title={
        <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
          <ModalHeading variant="h5">My Tasks</ModalHeading>
          <ModalLine style={{ width: '25%' }} />
          <ModalSubHead variant="subtitle2">{this.getSubHeading()}Task</ModalSubHead>
          <ModalLine style={{ width: '25%' }} />
        </div>}
      centered
      closable={true}
      footer={null}
      destroyOnClose
      width={742}
      style={{ paddingBottom: '15px', padding: '20px' }}
    >
      <div style={{ display: 'flex', alignItems: 'center', paddingLeft: '20px' }}>
        <Avatar alt="Remy Sharp" src={this.state.userProfile.attributes.image} />
        <div style={{ marginLeft: '10px' }}>
          <NameField style={{ fontSize: '14px' }}>{this.state.userProfile.attributes.preferred_name}</NameField>
          <DesignationField>{this.state.userProfile.attributes.role_id}</DesignationField>
        </div>
      </div>

      <div style={{ display: 'flex', flexDirection: "row", justifyContent: 'space-between', marginLeft: "20px", marginTop: "10px" }}>
        <CardSubTitle>Due Date*</CardSubTitle>
        <div style={{ display: 'flex' }}>
          <AssignedLabel className={this.state.taskDueDate === 'within_3_days' ? "selectedOption" : ""} style={{ cursor: "pointer" }}>
            <span
              data-test-id="3dayDue"
              onClick={() => this.setState({ taskDueDate: 'within_3_days' })}
            >
              Within 3 days
            </span>
            {this.state.taskDueDate === 'within_3_days' && <span data-test-id="cancel3days" onClick={() => this.setState({ taskDueDate: '' })}>{" x"}</span>}
          </AssignedLabel>
          <InProgressLabel
            className={this.state.taskDueDate === 'within_5_days' ? "selectedOption" : ""}
            style={{ margin: "0px 10px", cursor: "pointer" }}
          >
            <span
              data-test-id="5dayDue"
              onClick={() => this.setState({ taskDueDate: 'within_5_days' })}
            >
              Within 5 days
            </span>
            {this.state.taskDueDate === 'within_5_days' && <span data-test-id="cancel5days" onClick={() => this.setState({ taskDueDate: '' })}>{" x"}</span>}
          </InProgressLabel>
          <CompletedLabel style={{ cursor: "pointer" }}
            className={this.state.taskDueDate === 'within_1_week' ? "selectedOption" : ""}
          >
            <span
              data-test-id="withinWeekDue"
              onClick={() => this.setState({ taskDueDate: 'within_1_week' })}
            >
              Within 1 week
            </span>
            {this.state.taskDueDate === 'within_1_week' && <span data-test-id='cancel1Week' onClick={() => this.setState({ taskDueDate: '' })}>{" x"}</span>}
          </CompletedLabel>
        </div>
      </div>

      {!this.state.enableSubTask ?
        <>
          <Box sx={{ padding: "10px 10px 0px 20px" }}>
            <SideTitle>
              Task Title*
            </SideTitle>
            <InputField
              data-test-id="inputTitle"
              type="text"
              placeholder="Type here your task title"
              fullWidth
              value={this.state.title}
              onChange={(event) => this.handleInputTitle(event.target.value)}
            />
          </Box>
          <Box sx={{ padding: "10px 10px 0px 20px" }}>
            <SideTitle>
              Task Description*
            </SideTitle>
            <TaskDescriptionField
              data-test-id="inputDescription"
              multiline
              fullWidth
              minRows={4}
              inputProps={{ maxLength: 100 }}
              onChange={(event) => this.handleInputDescription(event.target.value)}
              placeholder="Type here your task description"
              value={this.state.description}
            />
            <CountChar>{this.state.description.length}/100</CountChar>
          </Box>
          <CheckLine style={{ padding: "10px 10px 0px 20px" }}>
            <TermsChecked
              data-test-id="checkImportant"
              onChange={(event) => this.checkImportant(event)}
              checked={this.state.createCheckStatus === "important"}
            />
            <MarkImportantTxt>Mark this task as an important task</MarkImportantTxt>
          </CheckLine>
        </>
        :
        <div className="scrollModal">
          {this.state.subTask.map((item, index) => this.renderField(item, index))}
          <AddNewButton data-test-id="btnAddNewTask" variant="text" onClick={this.addSubTask}>Add New Task</AddNewButton>
        </div>
      }
      <BackButton
        onClick={this.showAddModal}
        style={{ marginLeft: '20px' }}
      >
        {this.state.enableSubTask ? "Back" : "Cancel"}
      </BackButton>
      <NextButton
        data-test-id="enableAddSubTask"
        style={{ width: '65%', marginLeft: '10px' }}
        onClick={this.selectOperation}
        disabled={!this.state.title || !this.state.description || !this.state.taskDueDate}
      >
        {this.getButtonText()}
      </NextButton>
      <ToastContainer />
    </FieldModal>
  )

  getSubHeading = () => {
    if (this.state.enableSubTask) {
      return "Allocate "
    } else if (this.state.editMode) {
      return "Edit "
    } else {
      return "Add "
    }
  }

  getButtonText = () => {
    if (this.state.enableSubTask) {
      if (this.state.editMode) {
        return "Save"
      } else {
        return 'Done,Add Task'
      }
    } else {
      return 'Next'
    }
  }

  selectOperation = () => {
    if (this.state.enableSubTask) {
      if (this.state.editMode) {
        this.editTask(String(this.state.selectedId))
      } else {
        this.addTask()
      }
    } else {
      this.goToSubTask()
    }
  }

  renderCardSection = (item: ITask) => (
    <CardSection>
      <CardHeader>
        <div style={{ display: 'flex', flexDirection: 'column' }}>
          <CardTitle>{item.attributes.title}</CardTitle>
          <CardSubTitle>{item.attributes.description}</CardSubTitle>
        </div>
        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end' }}>
          <Box sx={{ position: 'relative', display: 'inline-flex' }}>
            <CircularProgress color="primary" variant="determinate" className="top-circle" value={item.attributes.completion_percentage} style={{ position: 'relative', left: '40', zIndex: 1 }} />
            <CircularProgress color="secondary" variant="determinate" className="bottom-circle" value={100} />
            <Box
              sx={{
                left: 40,
                top: 0,
                right: 0,
                bottom: 0,
                display: 'flex',
                position: 'absolute',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <ProgressPercentText variant="caption">{`${item.attributes.completion_percentage.toFixed(0)}%`}</ProgressPercentText>
            </Box>
          </Box>

          <DotSection>
            <IconButton
              aria-controls="menu-appbar"
              aria-haspopup="true"
              aria-label="account of current user"
              onClick={(event) => this.handleOptionMenu(event, item)}
              data-test-id="openOption"
              color="inherit"
            >
              <MoreVertOutlinedIcon />
            </IconButton>
            <OptionMenu
              id="menu-appbar"
              anchorEl={this.state.anchorOpen}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'right',
              }}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              keepMounted
              elevation={0}
              open={Boolean(this.state.anchorOpen)}
              onClose={() => this.setState({ anchorOpen: null })}
              style={{ top: 30 }}
            >
              <MenuItem data-test-id='editTask' onClick={this.showEditModal}>
                <img height={18} width={18} src={editButton} style={{ marginRight: 10 }} />
                <Typography style={{ fontSize: 14, color: '#475569' }}>{configJSON.editTask}</Typography>
              </MenuItem>

              <MenuItem data-test-id='deleteTask' onClick={() => { this.deleteTask(String(item.attributes.id)) }}>
                <img height={18} width={18} src={deleteIcon} style={{ marginRight: 10 }} />
                <Typography style={{ fontSize: 14, color: '#DC2626' }}>{configJSON.deleteTask}</Typography>
              </MenuItem>
              <Divider />
              <MenuItem>
                <Button
                  variant="text"
                  endIcon={<ArrowForwardIosIcon style={{ marginLeft: '80px', width: "14px" }} />}
                  style={{ fontSize: 14, color: '#1A469C', width: '100%', textTransform: 'capitalize', fontWeight: 400 }}
                  data-test-id='markTask'
                  aria-controls="menu-subOption"
                  aria-haspopup="true"
                  aria-label="Mark Task"
                  color="inherit"
                  onClick={(event) => this.handleSubOptionMenu(event, item.attributes.id)}
                >
                  {configJSON.markAs}
                </Button>
                <OptionMenu
                  id="menu-subOption"
                  anchorEl={this.state.anchorSubOpen}
                  anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right',
                  }}
                  transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                  }}
                  keepMounted
                  elevation={0}
                  open={Boolean(this.state.anchorSubOpen)}
                  onClose={() => this.setState({ anchorSubOpen: null })}
                  style={{ top: 10, left: 60 }}
                  getContentAnchorEl={null}
                >
                  {this.state.selectedItem.attributes.status !== 'important' && <MenuItem data-test-id='importantTask' onClick={() => this.changeStatus(item, 'important')}>
                    <MenuText>Important</MenuText>
                  </MenuItem>}
                  {this.state.selectedItem.attributes.status !== 'completed' && <MenuItem data-test-id='CompletedTask' onClick={() => this.changeStatus(item, 'completed')}>
                    <MenuText>Completed</MenuText>
                  </MenuItem>}
                  {this.state.selectedItem.attributes.status !== 'cancelled' && <MenuItem data-test-id='CancelledTask' onClick={() => this.changeStatus(item, 'cancelled')}>
                    <MenuText>Cancelled</MenuText>
                  </MenuItem>}
                  {this.state.selectedItem.attributes.status !== 'in_review' && <MenuItem data-test-id='InReviewTask' onClick={() => this.changeStatus(item, 'in_review')}>
                    <MenuText>In Review</MenuText>
                  </MenuItem>}
                  {this.state.selectedItem.attributes.status !== 'on_hold' && <MenuItem data-test-id='OnHoldTask' onClick={() => this.changeStatus(item, 'on_hold')}>
                    <MenuText>On Hold</MenuText>
                  </MenuItem>}
                </OptionMenu>
              </MenuItem>
            </OptionMenu>
          </DotSection>
        </div>
      </CardHeader>
      <CardBody>
        {item.attributes.task_lists?.slice(0, 2)?.map((item: ISubTask) => <CheckLine>
          <TermsChecked
            data-test-id="changeTaskProgress"
            checked={item.is_completed}
            onChange={(event) => this.changeTaskProgress(event, item)}
          />
          <CheckText>{item.name}</CheckText>
        </CheckLine>)}
        <Accordion expanded={item.attributes.is_open}>
          {item.attributes.is_open && <AccordionDetails>
            <div style={{ display: 'flex', flexDirection: "column" }}>
              {item.attributes.task_lists?.slice(2).map((item: ISubTask) => <CheckLine>
                <TermsChecked
                  data-test-id="changeTaskProgress"
                  checked={item.is_completed}
                  onChange={(event) => this.changeTaskProgress(event, item)}
                /><CheckText>{item.name}</CheckText>
              </CheckLine>)}
            </div>
          </AccordionDetails>}
          <AccordionSummary
            data-test-id="expandAccordion"
            expandIcon={item.attributes.is_open ? <ExpandLess /> : <ExpandMore />}
            onClick={() => this.handleAccordion(item)}
          >
            <div style={{ display: "flex", flexDirection: 'row', justifyContent: "space-between", width: '100%' }}>
              <div style={{ display: "flex", flexDirection: 'row' }}>
                <DateText>
                  Due Date:
                </DateText>
                {item.attributes.due_date === "within_3_days" && <AssignedLabel>WITHIN 3 DAYS</AssignedLabel>}
                {item.attributes.due_date === "within_5_days" && <InProgressLabel>WITHIN 5 DAYS</InProgressLabel>}
                {item.attributes.due_date === "within_1_week" && <CompletedLabel>WITHIN 1 WEEK</CompletedLabel>}
              </div>
              <DateText>
                {moment.utc(item.attributes.created_at).format('MM/DD/YYYY h:mm A')}
              </DateText>
            </div>
          </AccordionSummary>
        </Accordion>
      </CardBody>
    </CardSection>
  )

  showChart = (chartData: { month: string; completed_courses: number; }[]) => (
    <BarChart
      layout="vertical"
      height={173}
      width={480}
      data={chartData}
    >
      <CartesianGrid strokeDasharray="3 3" vertical={true} horizontal={false} />
      <XAxis
        axisLine={false}
        type="number"
        tick={true}
        tickLine={false}
        domain={[0, 100]}
        ticks={[10, 20, 30, 40, 50, 60, 70, 80, 90, 100]}
      />
      <YAxis
        dataKey="month"
        type="category"
      />
      <Bar
        fill="#6683BD"
        dataKey="completed_courses"
        radius={[0, 10, 10, 0]}
        barSize={10}
        background={{ fill: '#DDE1E6' }}
      />
    </BarChart>
  )
  // Customizable Area End

  render() {
    // Customizable Area Start
    return (
      <ThemeProvider theme={this.state.selectedTheme ? darkTheme : lightTheme}>
        <Loader loading={this.state.isLoading} />
        <HamburgerMenu
          navigation={this.props.navigation}
          handleMenu={this.toggleMenu}
          open={this.state.openMenu}
          themeDark={this.state.selectedTheme}
          handleTheme={this.changeTheme}
        />
        <OuterContainer style={{ paddingLeft: this.state.openMenu ? 273 : 140, paddingRight: 48, transition: 'all 300ms linear' }}>
          <BodyHeader>
            <div>
              <TaskListTitle>My Tasks List</TaskListTitle>
              <TaskListSubTitle>here where you add your tasks and track them</TaskListSubTitle>
            </div>
            <AddTaskButton
              data-test-id="btnAddTaskModal"
              startIcon={<AddBoxOutlinedIcon />}
              onClick={this.showAddModal}
            >
              Add Task
            </AddTaskButton>
          </BodyHeader>
          <Grid container>
            <LeftGrid item xs={"auto"}>
              <ButtonGroup>
                <Button
                  data-test-id='inProgressTask'
                  variant="text"
                  onClick={() => this.changeInprogress(true, false, false, 'in_progress')}
                  className={this.state.status === 'in_progress' ? "selectedButton" : ""}
                >
                  In Progress
                </Button>
                <Button
                  data-test-id='completedTask'
                  variant="text"
                  onClick={() => this.changeInprogress(false, true, false, 'completed')}
                  className={this.state.status === 'completed' ? "selectedButton" : ""}
                >
                  Completed
                </Button>
                <Button
                  data-test-id='importantTask'
                  variant="text"
                  onClick={() => this.changeInprogress(false, false, true, 'important')}
                  className={this.state.status === 'important' ? "selectedButton" : ""}
                >
                  Important
                </Button>
              </ButtonGroup>
              <Box style={{ height: '90%' }} className="scrollDiv">
                {this.state.taskList?.map((item) => (
                  this.renderCardSection(item)
                ))}
              </Box>
            </LeftGrid>
            <RightGrid item xs={"auto"}>
              <LeftGrid style={{ height: `${this.state.expandTaskProgress ? '50%' : '85%'}`, width: "100%" }}>
                <ButtonGroup>
                  <Button
                    data-test-id='inReviewTask'
                    variant="text"
                    onClick={() => this.changeReviewStatus(true, false, false, 'in_review')}
                    className={this.state.status1 === 'in_review' ? "selectedButton" : ""}
                  >
                    In Review
                  </Button>
                  <Button
                    data-test-id='onHoldTask'
                    variant="text"
                    onClick={() => this.changeReviewStatus(false, true, false, 'on_hold')}
                    className={this.state.status1 === 'on_hold' ? "selectedButton" : ""}
                  >
                    On Hold
                  </Button>
                  <Button
                    data-test-id='cancelledTask'
                    variant="text"
                    onClick={() => this.changeReviewStatus(false, false, true, 'cancelled')}
                    className={this.state.status1 === 'cancelled' ? "selectedButton" : ""}
                  >
                    Cancelled
                  </Button>
                </ButtonGroup>
                <Box className="scrollDiv" style={{ height: '80%' }}>
                  {this.state.taskList1?.map((item) => (
                    this.renderCardSection(item)
                  ))}
                </Box>
              </LeftGrid>
              <Accordion style={{ position: "fixed", bottom: 0, width: '41%', zIndex: 2, }} className="accordionStyle">
                <AccordionSummary
                  data-test-id="expandProgress"
                  onClick={() => this.setState({ expandTaskProgress: !this.state.expandTaskProgress })}
                >
                  <div style={{ display: "flex", justifyContent: 'space-between', width: '100%' }}>
                    <AccordionTitle>Tasks Progress</AccordionTitle><img src={this.state.expandTaskProgress ? reduce : expand} />
                  </div>
                </AccordionSummary>
                <AccordionDetails>{this.showChart([
                  {
                    month: 'Important',
                    completed_courses: this.state.taskProgressStatus?.percentage_important,
                  },
                  {
                    month: 'Completed',
                    completed_courses: this.state.taskProgressStatus?.percentage_completed,
                  },
                  {
                    month: 'Cancelled',
                    completed_courses: this.state.taskProgressStatus?.percentage_cancelled,
                  },
                  {
                    month: 'In Review',
                    completed_courses: this.state.taskProgressStatus?.percentage_in_review,
                  },
                  {
                    month: 'On Hold',
                    completed_courses: this.state.taskProgressStatus?.percentage_on_hold,
                  },])}
                </AccordionDetails>
              </Accordion>
            </RightGrid>
          </Grid>
          {this.AddTaskModal()}
          <ToastContainer
            containerId='B'
            closeButton={false}
            style={{ width: "463px", height: "42px" }}
            theme={this.state.selectedTheme ? "dark" : "light"}
          />
        </OuterContainer>
      </ThemeProvider >
    );
    // Customizable Area End
  }
}

// Customizable Area Start
export const OuterContainer = styled(Box)(({ theme }) => ({
  background: theme.palette.background.default,
  display: 'flex',
  alignItems: 'center',
  flexDirection: 'column',
  height: '100%',
  "& .Toastify__toast": {
    minHeight: '42px',
    padding: '0px 8px',
    color: `${theme.palette.info.main} !important`,
    background: `${theme.palette.primary.main} !important`,
    "& svg": {
      fill: theme.palette.warning.light
    }
  },
  "& .accordionStyle": {
    marginLeft: '10px',
    borderRadius: "18px",
    background: theme.palette.action.focus,
    "& .recharts-text": {
      fontSize: '10px',
      fontFamily: 'Rubik',
      lineHeight: '11.85px',
      fontWeight: 400,
      color: theme.palette.info.main
    },
    '& .MuiAccordion-root::before': {
      backgroundColor: 'transparent',
    },
    "& .MuiPaper-elevation1": {
      boxShadow: "none",
      background: theme.palette.background.default
    },
    '& .recharts-cartesian-axis-line': {
      display: 'none'
    },
    " & .recharts-bar-background-rectangle": {
      fill: theme.palette.warning.contrastText
    },
  }
}))

const TaskListTitle = styled(Typography)(({ theme }) => ({
  lineHeight: '30px',
  fontSize: '30px',
  color: theme.palette.info.main,
  fontWeight: 500,
}))

const TaskListSubTitle = styled(Typography)(({ theme }) => ({
  lineHeight: '14px',
  fontWeight: 400,
  letterSpacing: '0.41px',
  fontSize: '14px',
  color: theme.palette.info.main,
  marginTop: "10px"
}))

const BodyHeader = styled(Box)(({ theme }) => ({
  width: '100%',
  justifyContent: 'space-between',
  display: 'flex',
  margin: '10px',
  background: theme.palette.action.focus,
  height: "100px",
  borderRadius: "18px",
  marginBottom: '15px',
  padding: '20px'
}))

const AddTaskButton = styled(Button)(({ theme }) => ({
  width: '100%',
  maxWidth: '135px',
  height: '44px',
  border: `1px solid ${theme.palette.info.contrastText}`,
  borderRadius: '30px',
  textTransform: 'capitalize',
  padding: '10px 16px',
  fontSize: '16px',
  fontWeight: 500,
  color: theme.palette.info.contrastText,
  lineHeight: '24px',
  '& .MuiSvgIcon-root': {
    fill: theme.palette.info.contrastText
  }
}))

const CardSection = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  width: "96%",
  borderRadius: "6px",
  padding: '8px 12px 8px 16px',
  borderLeft: `4px solid ${theme.palette.action.selected}`,
  marginBottom: "10px",
  background: theme.palette.background.default,
  '& .MuiPaper-root': {
    background: theme.palette.background.default,
  },
  '& .MuiAccordion-root.Mui-expanded': {
    margin: 0
  },
  '& .MuiAccordionDetails-root': {
    padding: 0
  },
  '& .MuiPaper-elevation1': {
    boxShadow: 'none'
  },
  '& .MuiAccordion-root::before': {
    backgroundColor: "transparent"
  },
  '& .MuiSvgIcon-root': {
    fill: theme.palette.info.contrastText
  },
  '& .MuiAccordionSummary-root': {
    padding: 0
  }
}))

const CardHeader = styled(Box)(({ theme }) => ({
  width: '100%',
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
  "& .MuiCircularProgress-colorSecondary": {
    color: theme.palette.background.paper
  },
  "& .MuiCircularProgress-colorPrimary": {
    color: theme.palette.info.contrastText
  }
}))

const CardTitle = styled(Typography)(({ theme }) => ({
  fontSize: "16px",
  fontWeight: 500,
  lineHeight: '24px',
  color: theme.palette.secondary.main
}))

export const AccordionTitle = styled(Typography)(({ theme }) => ({
  fontSize: "18px",
  fontWeight: 500,
  lineHeight: '26px',
  color: theme.palette.info.main
}))

const CardSubTitle = styled(Typography)(({ theme }) => ({
  fontSize: "14px",
  fontWeight: 400,
  lineHeight: '14px',
  color: theme.palette.info.light
}))

const CardBody = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: 'column'
}))

const CheckText = styled(Typography)(({ theme }) => ({
  color: theme.palette.text.primary,
  fontFamily: 'Rubik',
  fontSize: '14px',
  fontWeight: 400,
  lineHeight: '22px',
  marginLeft: '5px',
  marginTop: '4px'
}))

const TermsChecked = styled(Checkbox)(({ theme }) => ({
  "& .ant-checkbox-inner": {
    borderRadius: '6px',
    height: '20px',
    width: '20px',
    top: 1,
    border: `1px solid ${theme.palette.info.light}`,
    background: theme.palette.primary.main
  },
  '& .ant-checkbox-input:hover': {
    borderRadius: '6px',
    borderColor: `${theme.palette.info.main}} !important`
  },
  '& .ant-checkbox-checked .ant-checkbox-inner': {
    background: theme.palette.info.contrastText
  },
  '& .ant-checkbox-checked .ant-checkbox-inner:hover': {
    borderRadius: '6px',
    borderColor: `${theme.palette.info.main}} !important`
  },
  '& .ant-checkbox-checked .ant-checkbox-inner::after': {
    borderColor: theme.palette.primary.main
  }
}))

const CheckLine = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: "row"
}))

const LeftGrid = styled(Grid)(({ theme }) => ({
  marginLeft: "10px",
  background: theme.palette.action.focus,
  height: '80vh',
  width: "48%",
  borderRadius: "18px",
  padding: '20px',
  "& .MuiButtonGroup-root": {
    borderRadius: '50px',
    background: theme.palette.background.default,
    width: "100%",
    marginBottom: '10px'
  },
  "& .MuiButtonGroup-grouped": {
    fontWeight: 500,
    textTransform: 'capitalize',
    lineHeight: '22px',
    fontsize: '14px',
    borderRadius: '30px',
    color: theme.palette.info.light,
    margin: '5px',
    padding: '5px',
    width: "100%"
  },
  "& .selectedButton": {
    background: theme.palette.background.paper,
    color: theme.palette.info.contrastText,
  },
  "& .recharts-text": {
    fontSize: '10px',
    fontFamily: 'Rubik',
    lineHeight: '11.85px',
    fontWeight: 400,
    color: theme.palette.info.main
  },
  '& .MuiAccordion-root::before': {
    backgroundColor: 'transparent',
  },
  "& .MuiPaper-elevation1": {
    boxShadow: "none",
    background: theme.palette.background.default
  },
  '& .recharts-cartesian-axis-line': {
    display: 'none'
  },
  "& .scrollDiv": {
    overflowY: 'scroll'
  },
  "& .scrollDiv::-webkit-scrollbar-track": {
    background: 'transparent',
    borderRadius: '30px 30px 90px 30px',
    border: `1px solid ${theme.palette.info.dark}`,
  },
  "& .scrollDiv::-webkit-scrollbar-thumb": {
    backgroundColor: theme.palette.background.paper,
    border: '1px solid #94A3B8',
    borderRadius: '30px 30px 90px 30px',
    width: '13px',
    height: '43px',
  },
  "& .scrollDiv::-webkit-scrollbar": {
    width: '13px',
    borderRadius: '30px 30px 90px 30px',
  },
}))

export const RightGrid = styled(Grid)(({ theme }) => ({
  width: "48%",
}))

const DateText = styled(Typography)(({ theme }) => ({
  color: theme.palette.info.light,
  fontFamily: 'Rubik',
  fontSize: '10px',
  fontWeight: 400,
  lineHeight: '19px',
  marginTop: '4px',
  marginRight: "10px"
}))

export const TaskDescriptionField = styled(TextField)(({ theme }) => ({
  borderRadius: '8px',
  fontFamily: 'Rubik',
  fontSize: '16px',
  fontWeight: 400,
  lineHeight: '24px',
  textAlign: 'left',
  color: theme.palette.info.main,
  background: theme.palette.background.default,
  "& ::placeholder": {
    color: theme.palette.info.light
  },
  "& .MuiInputBase-input": {
    margin: 0
  },
  "& .MuiInputBase-multiline": {
    padding: 0
  }
}))

export const SubTaskText = styled('input')(({ theme }) => ({
  fontFamily: 'Rubik',
  fontSize: '16px',
  height: '44px',
  fontWeight: 400,
  lineHeight: '24px',
  color: theme.palette.info.main,
  background: 'transparent',
  padding: '1px 12px',
  border: "none",
  width: "100%",
  margin: "10px 20px",
  "& ::placeholder": {
    color: theme.palette.info.light
  },
  "& ::selection": {
    background: 'transparent'
  }
}))

const AddNewButton = styled(Button)(({ theme }) => ({
  height: '22px',
  width: "100px",
  textTransform: "capitalize",
  fontFamily: "Rubik",
  fontSize: "12px",
  fontWeight: 500,
  lineHeight: "22px",
  float: "right",
  color: theme.palette.info.contrastText
}))

const ProgressPercentText = styled(Typography)(({ theme }) => ({
  fontFamily: "Rubik",
  fontSize: '8px',
  fontWeight: 400,
  color: theme.palette.secondary.main
}))

const OptionMenu = styled(Menu)(({ theme }) => ({
  '& .MuiPaper-root': {
    boxShadow: `rgba(0, 0, 0, 0.15) 0px 2px 4px 0px`,
    backgroundColor: theme.palette.background.default,
    width: '200px',
    borderRadius: '18px',
    border: `1px solid ${theme.palette.action.focus}`
  },
  '& .MuiListItem-button:hover': {
    backgroundColor: theme.palette.warning.main
  },
  "& .MuiList-padding": {
    paddingBottom: 0
  },
  "& .MuiButton-text": {
    padding: 0
  }
}))

const CountChar = styled('span')(({ theme }) => ({
  fontSize: "12px",
  fontWeight: 400,
  color: theme.palette.text.primary,
  textAlign: 'right',
  float: 'right'
}))

export const SideTitle = styled(Typography)(({ theme }) => ({
  fontFamily: 'Rubik',
  fontWeight: 400,
  fontSize: '14px',
  lineHeight: '22px',
  color: theme.palette.text.primary
}))

export const MarkImportantTxt = styled(Typography)(({ theme }) => ({
  color: theme.palette.info.main,
  fontFamily: 'Rubik',
  fontSize: '16px',
  fontWeight: 400,
  lineHeight: '24px',
  marginLeft: '5px',
}))

export const MenuText = styled(Typography)(({ theme }) => ({
  color: theme.palette.success.main,
  fontFamily: 'Rubik',
  fontSize: '14px',
  fontWeight: 400,
  lineHeight: '22px',
}))
// Customizable Area End
