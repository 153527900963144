import React from "react";
import {
  View,
  Text,
  Platform,
  StyleSheet,
  SafeAreaView,
  ScrollView,
  // Customizable Area Start
  // Customizable Area End
} from "react-native";
import { BlockComponent } from "../../framework/src/BlockComponent";
import AlertBlock from '../../blocks/alert/src/AlertBlock';
import CustomTextItem from "./CustomTextItem";
import NavigationBlock from "../../framework/src/Blocks/NavigationBlock";
import SingletonFactory from '../../framework/src/SingletonFactory';

import HomeScreenAdapter from '../../blocks/adapters/src/HomeScreenAdapter';
import InfoPageAdapter from '../../blocks/adapters/src/InfoPageAdapter';
import AlertPageWebAdapter from "../../blocks/adapters/src/AlertPageWebAdapter";

// Customizable Area Start
import PrivacyPolicyAdapter from "../../blocks/adapters/src/PrivacyPolicyAdapter";
import TermsAndConditionAdapter from "../../blocks/adapters/src/TermsAndConditionAdapter";
import SplashScreenAdapter from "../../blocks/adapters/src/SplashScreenAdapter";
import EmailAccountLogInAdapter from "../../blocks/adapters/src/EmailAccountLogInAdapter";
import AnalyticsAdapter from "../../blocks/adapters/src/AnalyticsAdapter";
import EmailAccountSignUpAdapter from "../../blocks/adapters/src/EmailAccountSignUpAdapter";
import ForgotPasswordAdapter from "../../blocks/adapters/src/ForgotPasswordAdapter";
import MobilePhoneToOTPAdapter from "../../blocks/adapters/src/MobilePhoneToOTPAdapter";
import OtpToNewPasswordAdapter from "../../blocks/adapters/src/OtpToNewPasswordAdapter";
import SocialMediaAccountLoginAdapter from "../../blocks/adapters/src/SocialMediaAccountLoginAdapter";
import OnboardingAdapter from "../../blocks/adapters/src/OnboardingAdapter";
import MobilePhoneLogInAdapter from "../../blocks/adapters/src/MobilePhoneLogInAdapter";
import MobilePhoneToAdditionalDetailsAdapter from "../../blocks/adapters/src/MobilePhoneToAdditionalDetailsAdapter";
import Settings2Adapter from "../../blocks/adapters/src/Settings2Adapter";
import UserProfileBasicBlockAdapter from "../../blocks/adapters/src/UserProfileBasicBlockAdapter";
import CustomisableUserProfilesAdapter from "../../blocks/adapters/src/CustomisableUserProfilesAdapter";
import NotificationSettingsAdapter from "../../blocks/adapters/src/NotificationSettingsAdapter";
import BulletinBoardAdapter from "../../blocks/adapters/src/BulletinBoardAdapter";
import CertificationTrackingAdapter from "../../blocks/adapters/src/CertificationTrackingAdapter";
import TaskAdapter from "../../blocks/adapters/src/TaskAdapter";
import TaskListAdapter from "../../blocks/adapters/src/TaskListAdapter";


//Assembler generated adapters start
const emailAccountLogInAdapter = new EmailAccountLogInAdapter();
const analyticsAdapter = new AnalyticsAdapter();
const emailAccountSignUpAdapter = new EmailAccountSignUpAdapter();
const forgotPasswordAdapter = new ForgotPasswordAdapter();
const mobilePhoneToOTPAdapter = new MobilePhoneToOTPAdapter();
const otpToNewPasswordAdapter = new OtpToNewPasswordAdapter();
const socialMediaAccountLoginAdapter = new SocialMediaAccountLoginAdapter();
const onboardingAdapter = new OnboardingAdapter();
const mobilePhoneLogInAdapter = new MobilePhoneLogInAdapter();
const mobilePhoneToAdditionalDetailsAdapter = new MobilePhoneToAdditionalDetailsAdapter();

//Assembler generated adapters end



const privacyAdapter = new PrivacyPolicyAdapter();
const termAndConditionAdapter = new TermsAndConditionAdapter();
const splashScreenAdapter = new SplashScreenAdapter();
const settings2Adapter = new Settings2Adapter();
const userprofilebasicblockAdapter = new UserProfileBasicBlockAdapter();
const customisableuserprofilesAdapter = new CustomisableUserProfilesAdapter();
const notificationsettingsAdapter = new NotificationSettingsAdapter()
const bulletinboardAdapter =new BulletinBoardAdapter();
const certificationTrackingAdapter =new CertificationTrackingAdapter();
const taskAdapter =new TaskAdapter();
const tasklistAdapter =new TaskListAdapter();

// Customizable Area End


const restAPIBlock = SingletonFactory.getRestBlockInstance();
const alertBlock = new AlertBlock();
const navigationBlock = new NavigationBlock();
const sessionBlock = SingletonFactory.getSessionBlockInstance();
const userAccountManagerBlock = SingletonFactory.getUserManagerInstance();
const homeScreenAdapter = new HomeScreenAdapter();
const infoPageAdapter = new InfoPageAdapter();
const alertPageWebAdapter = new AlertPageWebAdapter()

const instructions = Platform.select({
  // Customizable Area Start
  ios: "The iOS APP to rule them all!",
  android: "Now with Android AI",
  web: "Selector your adventure."
  // Customizable Area End
});

interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

// Customizable Area Start
interface S { }

interface SS { }

class HomeScreen extends BlockComponent<Props, S, SS> {

  static instance:HomeScreen;

  constructor(props: Props) {
    super(props);
    HomeScreen.instance = this;
  }

  render() {
    const { navigation } = this.props;
    const _this = this;

    return (
      <SafeAreaView>
        <ScrollView contentContainerStyle={styles.scrollView} bounces={false}>
          <View style={styles.container}>
            <View style={styles.header}>
              <Text style={styles.welcome}>
                Welcome to PetroPal!
              </Text>
            </View>

            <Text style={styles.instructions}>{instructions}</Text>
            <Text style={styles.header}>DEFAULT BLOCKS</Text>
            <CustomTextItem
              content={'InfoPage'}
              onPress={() => navigation.navigate("InfoPage")}
            />
            <CustomTextItem
              content={'Alert'}
              onPress={() => this.showAlert("Example", "This happened")}
            />
<CustomTextItem content={'searchhistory'}  onPress={() => this.showAlert("Error", "Could not determine assembler export")} />
<CustomTextItem content={'core'}  onPress={() => this.showAlert("Error", "Could not determine assembler export")} />
<CustomTextItem content={'audiolibrary'}  onPress={() => this.showAlert("Error", "Could not determine assembler export")} />
<CustomTextItem content={'utilities'}  onPress={() => this.showAlert("Error", "Could not determine assembler export")} />
<CustomTextItem content={'Tasks'}  onPress={() => navigation.navigate("Tasks")} />
<CustomTextItem content={'AccountGroups'}  onPress={() => navigation.navigate("AccountGroups")} />
<CustomTextItem content={'teambuilder'}  onPress={() => this.showAlert("Error", "Could not determine assembler export")} />
<CustomTextItem content={'Catalogue'}  onPress={() => navigation.navigate("Catalogue")} />
<CustomTextItem content={'NavigationMenu'}  onPress={() => navigation.navigate("NavigationMenu")} />
<CustomTextItem content={'EmailAccountLoginBlock'}  onPress={() => navigation.navigate("EmailAccountLoginBlock")} />
<CustomTextItem content={'EmailAccountRegistration'}  onPress={() => navigation.navigate("EmailAccountRegistration")} />
<CustomTextItem content={'CountryCodeSelector'}  onPress={() => navigation.navigate("CountryCodeSelector")} />
<CustomTextItem content={'ForgotPassword'}  onPress={() => navigation.navigate("ForgotPassword")} />
<CustomTextItem content={'OTPInputAuth'}  onPress={() => navigation.navigate("OTPInputAuth")} />
<CustomTextItem content={'SocialMediaAccountLogin'}  onPress={() => navigation.navigate("SocialMediaAccountLogin")} />
<CustomTextItem content={'CameraAccess'}  onPress={() => navigation.navigate("CameraAccess")} />
<CustomTextItem content={'automaticreminders'}  onPress={() => this.showAlert("Error", "Could not determine assembler export")} />
<CustomTextItem content={'Scheduling'}  onPress={() => navigation.navigate("Scheduling")} />
<CustomTextItem content={'Dashboard'}  onPress={() => navigation.navigate("Dashboard")} />
<CustomTextItem content={'ApiIntegration'}  onPress={() => navigation.navigate("ApiIntegration")} />
<CustomTextItem content={'dataencryption'}  onPress={() => this.showAlert("Error", "Could not determine assembler export")} />
<CustomTextItem content={'Analytics'}  onPress={() => navigation.navigate("Analytics")} />
<CustomTextItem content={'offlinework'}  onPress={() => this.showAlert("Error", "Could not determine assembler export")} />
<CustomTextItem content={'OfflineBrowsing'}  onPress={() => navigation.navigate("OfflineBrowsing")} />
<CustomTextItem content={'BulkUploading'}  onPress={() => navigation.navigate("BulkUploading")} />
<CustomTextItem content={'breadcrumbnavigation'}  onPress={() => navigation.navigate("BreadcrumbNavigation")} />
<CustomTextItem content={'Videos'}  onPress={() => navigation.navigate("Videos")} />
<CustomTextItem content={'Onboardingguide'}  onPress={() => navigation.navigate("Onboardingguide")} />
<CustomTextItem content={'Pushnotifications'}  onPress={() => navigation.navigate("Pushnotifications")} />
<CustomTextItem content={'Notifications'}  onPress={() => navigation.navigate("Notifications")} />
<CustomTextItem content={'TermsConditions'}  onPress={() => navigation.navigate("TermsConditions")} />
<CustomTextItem content={'Splashscreen'}  onPress={() => navigation.navigate("Splashscreen")} />
<CustomTextItem content={'UserProfileBasicBlock'}  onPress={() => navigation.navigate("UserProfileBasicBlock")} />
<CustomTextItem content={'EducationalUserProfile'}  onPress={() => navigation.navigate("EducationalUserProfile")} />
<CustomTextItem content={'customisableuserprofiles'}  onPress={() => navigation.navigate("CustomisableUserProfiles")} />
<CustomTextItem content={'contentmanagement'}  onPress={() => this.showAlert("Error", "Could not determine assembler export")} />
<CustomTextItem content={'Categoriessubcategories'}  onPress={() => navigation.navigate("Categoriessubcategories")} />
<CustomTextItem content={'certificationtracking'}  onPress={() => navigation.navigate("CertificationTracking")} />
<CustomTextItem content={'AdvancedSearch'}  onPress={() => navigation.navigate("AdvancedSearch")} />
<CustomTextItem content={'PhoneNumberInput'}  onPress={() => navigation.navigate("PhoneNumberInput")} />
<CustomTextItem content={'customform'}  onPress={() => this.showAlert("Error", "Could not determine assembler export")} />
<CustomTextItem content={'AssessmentTest'}  onPress={() => navigation.navigate("AssessmentTest")} />
<CustomTextItem content={'Filteritems'}  onPress={() => navigation.navigate("Filteritems")} />
<CustomTextItem content={'ImportExportData'}  onPress={() => navigation.navigate("ImportExportData")} />
<CustomTextItem content={'scoring'}  onPress={() => this.showAlert("Error", "Could not determine assembler export")} />
<CustomTextItem content={'Notificationsettings'}  onPress={() => navigation.navigate("Notificationsettings")} />
<CustomTextItem content={'MobileAccountLoginBlock'}  onPress={() => navigation.navigate("MobileAccountLoginBlock")} />
<CustomTextItem content={'DailyScheduleNotifications'}  onPress={() => navigation.navigate("DailyScheduleNotifications")} />
<CustomTextItem content={'VideoLibrary'}  onPress={() => navigation.navigate("VideoLibrary")} />
<CustomTextItem content={'youtubeintegration'}  onPress={() => this.showAlert("Error", "Could not determine assembler export")} />
<CustomTextItem content={'PerformanceTracker'}  onPress={() => navigation.navigate("PerformanceTracker")} />
<CustomTextItem content={'multipageforms'}  onPress={() => this.showAlert("Error", "Could not determine assembler export")} />
<CustomTextItem content={'Download'}  onPress={() => navigation.navigate("Download")} />
<CustomTextItem content={'QrCodes'}  onPress={() => navigation.navigate("QrCodes")} />
<CustomTextItem content={'chatbot'}  onPress={() => this.showAlert("Error", "Could not determine assembler export")} />
<CustomTextItem content={'chatgpt'}  onPress={() => this.showAlert("Error", "Could not determine assembler export")} />
<CustomTextItem content={'projectnotes'}  onPress={() => this.showAlert("Error", "Could not determine assembler export")} />
<CustomTextItem content={'DownloadOptions'}  onPress={() => navigation.navigate("DownloadOptions")} />
<CustomTextItem content={'manageblogcomments'}  onPress={() => this.showAlert("Error", "Could not determine assembler export")} />
<CustomTextItem content={'blogpostsmanagement'}  onPress={() => this.showAlert("Error", "Could not determine assembler export")} />
<CustomTextItem content={'multilevelapproval'}  onPress={() => this.showAlert("Error", "Could not determine assembler export")} />
<CustomTextItem content={'CustomProfileHeader'}  onPress={() => navigation.navigate("CustomProfileHeader")} />
<CustomTextItem content={'LandingPage'}  onPress={() => navigation.navigate("LandingPage")} />
<CustomTextItem content={'PdfEdit'}  onPress={() => navigation.navigate("PdfEdit")} />
<CustomTextItem content={'DragDropInterface'}  onPress={() => navigation.navigate("DragDropInterface")} />
<CustomTextItem content={'bulletinboard'}  onPress={() =>  navigation.navigate("BulletinBoard")} />
<CustomTextItem content={'hamburgermenu'}  onPress={() => this.showAlert("Error", "Could not determine assembler export")} />
<CustomTextItem content={'contentmoderation'}  onPress={() => this.showAlert("Error", "Could not determine assembler export")} />
<CustomTextItem content={'DataStorage'}  onPress={() => navigation.navigate("DataStorage")} />
<CustomTextItem content={'audittrail'}  onPress={() => this.showAlert("Error", "Could not determine assembler export")} />
<CustomTextItem content={'SaveAsPdf'}  onPress={() => navigation.navigate("SaveAsPdf")} />
<CustomTextItem content={'SpellCheck'}  onPress={() => navigation.navigate("SpellCheck")} />
<CustomTextItem content={'digitalsignatures'}  onPress={() => this.showAlert("Error", "Could not determine assembler export")} />
<CustomTextItem content={'notes'}  onPress={() => this.showAlert("Error", "Could not determine assembler export")} />
<CustomTextItem content={'DocumentDistribution'}  onPress={() => navigation.navigate("DocumentDistribution")} />
<CustomTextItem content={'DocumentOpener'}  onPress={() => navigation.navigate("DocumentOpener")} />
<CustomTextItem content={'PostCreation'}  onPress={() => navigation.navigate("PostCreation")} />
<CustomTextItem content={'fileattachment'}  onPress={() => this.showAlert("Error", "Could not determine assembler export")} />
<CustomTextItem content={'annotations'}  onPress={() => this.showAlert("Error", "Could not determine assembler export")} />
<CustomTextItem content={'archive'}  onPress={() => this.showAlert("Error", "Could not determine assembler export")} />
<CustomTextItem content={'passwordprotectedpages'}  onPress={() => this.showAlert("Error", "Could not determine assembler export")} />
<CustomTextItem content={'Comment'}  onPress={() => navigation.navigate("Comment")} />
<CustomTextItem content={'Share'}  onPress={() => navigation.navigate("Share")} />
<CustomTextItem content={'deeplinking'}  onPress={() => this.showAlert("Error", "Could not determine assembler export")} />
<CustomTextItem content={'automatedpublishing'}  onPress={() => this.showAlert("Error", "Could not determine assembler export")} />
<CustomTextItem content={'documentation'}  onPress={() => this.showAlert("Error", "Could not determine assembler export")} />
<CustomTextItem content={'PhotoLibrary'}  onPress={() => navigation.navigate("PhotoLibrary")} />
<CustomTextItem content={'Uploadmedia3'}  onPress={() => navigation.navigate("Uploadmedia3")} />
<CustomTextItem content={'Settings2'}  onPress={() => navigation.navigate("Settings2")} />
<CustomTextItem content={'Adminconsole2'}  onPress={() => navigation.navigate("Adminconsole2")} />
<CustomTextItem content={'Rolesandpermissions2'}  onPress={() => navigation.navigate("Rolesandpermissions2")} />
<CustomTextItem content={'Emailnotifications2'}  onPress={() => navigation.navigate("Emailnotifications2")} />
<CustomTextItem content={'Customfeature1559826823644'}  onPress={() => navigation.navigate("Customfeature1559826823644")} />
<CustomTextItem content={'Trash'}  onPress={() => navigation.navigate("Trash")} />
<CustomTextItem content={'Readermode'}  onPress={() => navigation.navigate("Readermode")} />
<CustomTextItem content={'Customiseinterface2'}  onPress={() => navigation.navigate("Customiseinterface2")} />
<CustomTextItem content={'Richtexteditor1'}  onPress={() => navigation.navigate("Richtexteditor1")} />

          </View>
        </ScrollView>
      </SafeAreaView>
    );
  }
}
// Customizable Area End

// Customizable Area Start
const styles = StyleSheet.create({
  scrollView: {
    flexGrow: 1,
    height: Platform.OS === "web" ? '100vh' : 'auto',
    backgroundColor: "#F5FCFF"
  },
  container: {
    flex: 1
  },
  welcome: {
    fontSize: 20,
    textAlign: "center",
    fontWeight: "bold",
    color: "white"
  },
  instructions: {
    textAlign: "center",
    color: "#6200EE",
    marginBottom: 5,
    fontWeight: 'bold',
    fontSize: 16,

    padding: 10
  },
  button: {
    backgroundColor: '#6200EE',
    padding: 15,
    color: 'white',
    fontSize: 16,
    fontWeight: 'bold'
  },
  header: {
    backgroundColor: '#6200EE',
    padding: 15,
    color: 'white',
    fontSize: 16,
    fontWeight: 'bold'
  },
  item: {
    backgroundColor: '#00000000',
    padding: 18,
    color: '#6200EE',
    fontSize: 16,
    fontWeight: 'normal'
  }
});
// Customizable Area End
export default HomeScreen;