import { BlockComponent } from "../../../framework/src/BlockComponent";
import { IBlock } from "../../../framework/src/IBlock";
import { runEngine } from "../../../framework/src/RunEngine";
import { Message } from "../../../framework/src/Message";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";

// Customizable Area Start
import * as Yup from "yup";
import { imgPasswordVisible, imgPasswordInVisible } from "./assets";
import { toast } from "react-toastify";
import { getStorageData, setStorageData } from "../../../framework/src/Utilities";
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  accountType: string;
  emailSchema: any;
  phoneSchema: any;
  otpSchema: any;
  passwordSchema: any;
  accountStatus: any;
  passwordRules: any;
  emailValue: string;
  phoneValue: any;
  countryCodeSelected: any;
  token: any;
  enablePasswordField: Boolean;
  btnConfirmPasswordShowHide: Boolean;
  darkThemeSelected: boolean;
  emailError: string;
  mailSuccessMsg: string;
  enableNewPassword: boolean;
  newPasswordMsg: string;
  passwordNew: string;
  confirmPasswordNew: string;
  isCharLength: boolean;
  isCharUpper: boolean;
  isCharLower: boolean;
  isCharSpecial: boolean;
  isCharNumber: boolean;
  showNewPassword: boolean;
  showNewConfirmPassword: boolean;
  paramsToken: string;
  // Customizable Area End
}

interface SS {
  // Customizable Area Start
  navigation: any;
  // Customizable Area End
}

// Customizable Area Start
// Customizable Area End

export default class ForgotPasswordController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  validationAPICallId: any;
  requestEmailOtpCallId: any;
  requestPhoneOtpCallId: any;
  requestChangePasswordCallId: any;
  requestGoToConfirmationCallId: any;
  otpToken: any;
  isChangePassword: boolean = false;
  recoveryEmailApiCallId: string = "";
  validateTokenApiCallId: string = "";
  resetPasswordApiCallId: string = "";

  //Properties from config
  labelTextIsAccountRecovery: string = configJSON.labelTextIsAccountRecovery;
  secondLabelText: string = configJSON.secondLabelText;
  thirdLabelText: string = configJSON.thirdLabelText;
  forthLabelText: string = configJSON.forthLabelText;
  fifthLabelText: string = configJSON.fifthLabelText;
  sixthLabelText: string = configJSON.sixthLabelText;
  firstInputAutoCompleteType: any = configJSON.firstInputAutoCompleteType;
  firstInputKeyboardStyle: any = configJSON.firstInputKeyboardStyle;
  firstInputPlaceholder: string = configJSON.firstInputPlaceholder;
  firstInputErrorColor: any = configJSON.firstInputErrorColor;
  buttonTextIsNext: string = configJSON.buttonTextIsNext;
  buttonColorForNextButton: any = configJSON.buttonColorForNextButton;
  secondInputAutoCompleteType: any = configJSON.secondInputAutoCompleteType;
  secondInputKeyboardType: any = configJSON.secondInputKeyboardType;
  secondInputPlaceholder: string = configJSON.secondInputPlaceholder;
  secondInputErrorColor: any = configJSON.secondInputErrorColor;
  thirdInputPlaceholder: string = configJSON.thirdInputPlaceholder;
  thirdInputErrorColor: any = configJSON.thirdInputErrorColor;
  buttonTitleIsSMSPhoneAccount: string =
    configJSON.buttonTitleIsSMSPhoneAccount;
  buttonTitleIsEmailAccount: string = configJSON.buttonTitleIsEmailAccount;
  labelTextIsPleaseEnterYourNewPassword: string =
    configJSON.labelTextIsPleaseEnterYourNewPassword;
  labelTextIsYourPasswordHasBeenSuccessfullyChanged: string =
    configJSON.labelTextIsYourPasswordHasBeenSuccessfullyChanged;
  placeholderIsPassword: string = configJSON.placeholderIsPassword;
  imgPasswordInVisible: any = imgPasswordInVisible;
  imgPasswordVisible: any = imgPasswordVisible;
  placeholderIsReTypePassword: string = configJSON.placeholderIsReTypePassword;
  buttonTitleIsOk: string = configJSON.buttonTitleIsOk;
  buttonColorForOkButton: any = configJSON.buttonColorForOkButton;
  countryCodeSelectorPlaceholder: string =
    configJSON.countryCodeSelectorPlaceholder;
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.subScribedMessages = [
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.NavigationPayLoadMessage),
      getName(MessageEnum.CountryCodeMessage),
      // Customizable Area End
    ];

    this.receive = this.receive.bind(this);

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    //email schema
    let emailSchema = {
      email: Yup.string()
        .email(configJSON.pleaseEnterAValidEmail)
        .required(configJSON.emailIsRequired),
    };

    //phone schema
    let phoneSchema = {
      // countryCode: Yup.number()
      // .required("Country code is required"),

      phone: Yup.string()
        .matches(configJSON.phoneRegExp, configJSON.phoneNumberIsNotValid)
        .required(configJSON.phoneNumberIsRequired),
    };

    //otpSchema
    let otpSchema = {
      otpCode: Yup.number().min(4).required(configJSON.otpCodeIsRequired),
    };

    //passwordSchema
    let passwordSchema = {
      password: Yup.string()
        .required(configJSON.pleaseEnterAPassword)
        .min(2, configJSON.passwordMustBeAtLeast2Characters),
      confirmPassword: Yup.string()
        .required(configJSON.pleaseConfirmYourPassword)
        .when("password", {
          is: (val) => (val && val.length > 0 ? true : false),
          then: Yup.string().oneOf(
            [Yup.ref("password")],
            configJSON.passwordsMustMatch
          ),
        }),
    };

    this.state = {
      accountType: "sms",
      accountStatus: "ChooseAccountType",
      emailValue: "",
      phoneValue: "",
      countryCodeSelected: "",
      passwordRules: "",
      emailSchema: emailSchema,
      phoneSchema: phoneSchema,
      otpSchema: otpSchema,
      passwordSchema: passwordSchema,
      token: "",
      enablePasswordField: true,
      btnConfirmPasswordShowHide: true,
      darkThemeSelected: false,
      emailError: "",
      mailSuccessMsg: "",
      enableNewPassword: false,
      newPasswordMsg: "",
      passwordNew: "",
      confirmPasswordNew: "",
      isCharLower: false,
      isCharUpper: false,
      isCharLength: false,
      isCharNumber: false,
      isCharSpecial: false,
      showNewPassword: false,
      showNewConfirmPassword: false,
      paramsToken: ""
    };
    // Customizable Area End
  }

  async componentDidMount() {
    super.componentDidMount();
    // Customizable Area Start
    const url = new URL(window.location.href)
    const tokenParam = url.searchParams.get('token')
    if (tokenParam) {
      this.setState({ paramsToken: tokenParam })
      this.validateToken(tokenParam)
    }
    let theme = await getStorageData('darkMode')
    if (theme === 'true') {
      this.setState({ darkThemeSelected: true })
    }
    // Customizable Area End
  }

  validationRulesRequest = () => {
    const header = {
      "Content-Type": configJSON.forgotPasswordAPiContentType,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.validationAPICallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.profileValidationSettingsAPiEndPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpGetMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  async receive(from: string, message: Message) {
    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      var responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      var errorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );
      runEngine.debugLog("API Message Recived", message);
      if (responseJson && responseJson.errors) {
        if (responseJson.errors.hasOwnProperty("email")) {
          this.setState({ emailError: responseJson.errors.email[0] })
        } else {
          toast.error(responseJson.errors.token[0])
        }
      } else if (responseJson) {
        if (apiRequestCallId === this.recoveryEmailApiCallId) {
          this.setState({ mailSuccessMsg: responseJson.meta.message });
        }
        if (apiRequestCallId === this.validateTokenApiCallId) {
          this.setState({ enableNewPassword: true, newPasswordMsg: responseJson.message });
        }
        if (apiRequestCallId === this.resetPasswordApiCallId) {
          toast.success(responseJson.meta.message)
          this.goToLogin()
        }

        if (
          getName(MessageEnum.RestAPIResponceMessage) === message.id &&
          this.requestEmailOtpCallId !== null &&
          this.requestEmailOtpCallId ===
          message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
        ) {
          // console.log("entered email!!!!");
          var responseJson = message.getData(
            getName(MessageEnum.RestAPIResponceSuccessMessage)
          );

          if (
            responseJson !== undefined &&
            responseJson.meta &&
            responseJson.meta.token
          ) {
            this.otpToken = responseJson.meta.token;

            this.setState({ token: this.otpToken });

            //navigate to OTP page
            const msg: Message = new Message(
              getName(MessageEnum.NavigationMobilePhoneOTPMessage)
            );

            msg.addData(
              getName(MessageEnum.AuthTokenDataMessage),
              this.state.token
            );

            msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);

            msg.addData(
              getName(MessageEnum.AuthTokenEmailMessage),
              this.state.emailValue
            );

            msg.addData(getName(MessageEnum.EnterOTPAsForgotPasswordMessage), true);

            this.send(msg);
          }
          //error handling
          else if (responseJson && responseJson.errors) {
            this.parseApiErrorResponse(responseJson);
          } else {
            var errorReponse = message.getData(
              getName(MessageEnum.RestAPIResponceErrorMessage)
            );

            this.parseApiCatchErrorResponse(errorReponse);
          }
        } else if (
          getName(MessageEnum.RestAPIResponceMessage) === message.id &&
          this.requestPhoneOtpCallId !== null &&
          this.requestPhoneOtpCallId ===
          message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
        ) {
          // console.log("entered phone!!!!");
          var responseJson = message.getData(
            getName(MessageEnum.RestAPIResponceSuccessMessage)
          );

          if (
            responseJson !== undefined &&
            responseJson.meta &&
            responseJson.meta.token
          ) {
            this.otpToken = responseJson.meta.token;
            this.setState({ token: this.otpToken });

            const msg: Message = new Message(
              getName(MessageEnum.NavigationMobilePhoneOTPMessage)
            );
            msg.addData(
              getName(MessageEnum.AuthTokenDataMessage),
              this.state.token
            );

            msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);

            msg.addData(
              getName(MessageEnum.AuthTokenPhoneNumberMessage),
              this.state.phoneValue
            );

            msg.addData(getName(MessageEnum.EnterOTPAsForgotPasswordMessage), true);

            this.send(msg);
          }
          //error handling
          else if (responseJson && responseJson.errors) {
            this.parseApiErrorResponse(responseJson);
          } else {
            var errorReponse = message.getData(
              getName(MessageEnum.RestAPIResponceErrorMessage)
            );

            this.parseApiCatchErrorResponse(errorReponse);
          }
        } else if (
          getName(MessageEnum.RestAPIResponceMessage) === message.id &&
          this.requestGoToConfirmationCallId !== null &&
          this.requestGoToConfirmationCallId ===
          message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
        ) {
          // console.log("entered 3");
          var responseJson = message.getData(
            getName(MessageEnum.RestAPIResponceSuccessMessage)
          );
          if (responseJson !== undefined && responseJson.data) {
            this.setState({
              accountStatus: "Confirmation",
            });
          } else if (responseJson !== undefined && responseJson.errors) {
            this.parseApiErrorResponse(responseJson);
          } else {
            var errorReponse = message.getData(
              getName(MessageEnum.RestAPIResponceErrorMessage)
            );

            this.parseApiCatchErrorResponse(errorReponse);
          }
        } else if (getName(MessageEnum.CountryCodeMessage) === message.id) {
          var selectedCode = message.getData(
            getName(MessageEnum.CountyCodeDataMessage)
          );

          if (selectedCode !== undefined) {
            this.setState({
              countryCodeSelected:
                selectedCode.indexOf("+") > 0
                  ? selectedCode.split("+")[1]
                  : selectedCode,
            });
          }
        }
      }
    }
    // Customizable Area End
  }

  startForgotPassword(accountType: String) {
    this.setState({
      accountStatus: accountType === "sms" ? "EnterPhone" : "EnterEmail",
    });
  }

  goToOtpAfterEmailValidation(values: { accountType: string; email: string }) {
    //change status to OTP
    const header = {
      "Content-Type": configJSON.forgotPasswordAPiContentType,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    //GO TO REQUEST STATE
    this.requestEmailOtpCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.passwordRecoveryStartOtpAPiEndPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    this.setState({
      emailValue: values.email ? values.email : "",
    });

    const data = {
      type: values.accountType ? values.accountType : "email_account",
      attributes: {
        email: values.email ? values.email : "",
      },
    };

    const httpBody = {
      data: data,
    };

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpPostMethod
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  goToOtpAfterPhoneValidation(values: { phone: string }) {
    // console.log("entered phone validation code");
    if (
      !this.state.countryCodeSelected ||
      this.state.countryCodeSelected.length === 0
    ) {
      this.showAlert(
        configJSON.goToOtpAfterPhoneValidationErrorTitle,
        configJSON.goToOtpAfterPhoneValidationErrorBody
      );
      return;
    }
    console.log(this.state.countryCodeSelected);
    //change status to OTP
    const header = {
      "Content-Type": configJSON.forgotPasswordAPiContentType,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    //GO TO REQUEST STATE
    this.requestPhoneOtpCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.passwordRecoveryStartOtpAPiEndPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    this.setState({
      phoneValue:
        this.state.countryCodeSelected && values.phone
          ? this.state.countryCodeSelected + values.phone
          : "",
    });

    const data = {
      type: "sms_account",
      attributes: {
        full_phone_number: this.state.phoneValue,
      },
    };

    const httpBody = {
      data: data,
    };

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpPostMethod
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  goToChangePasswordAfterOtp(values: { otpCode: string }) {
    //change status to change password
    //change status to OTP
    const header = {
      "Content-Type": configJSON.forgotPasswordAPiContentType,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    //GO TO REQUEST STATE
    this.requestChangePasswordCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.passwordRecoveryConfirmOtpAPiEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    const data = {
      token: this.otpToken ? this.otpToken : "",
      otp_code: values.otpCode ? values.otpCode : "",
    };

    const httpBody = {
      data: data,
    };

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpPostMethod
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  goToConfirmationAfterPasswordChange(values: {
    password: any;
    confirmPassword: any;
  }) {
    const header = {
      "Content-Type": configJSON.forgotPasswordAPiContentType,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    //GO TO REQUEST STATE
    this.requestGoToConfirmationCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.passwordRecoveryChangePasswordAPiEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    const data = {
      token: this.otpToken ? this.otpToken : "",
      new_password: values.password,
    };

    const httpBody = {
      data: data,
    };

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpPostMethod
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  goToHome() {
    const msg: Message = new Message(
      getName(MessageEnum.NavigationHomeScreenMessage)
    );
    msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(msg);
  }

  goToLogin() {
    const msg: Message = new Message(
      getName(MessageEnum.NavigationEmailLogInMessage)
    );
    msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(msg);
  }

  // Customizable Area Start
  changeNewPassword = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event?.target.value
    if (/\d/.test(value)) {
      this.setState({ isCharNumber: true })
    } else {
      this.setState({ isCharNumber: false })
    }
    if (/[ `!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/.test(value)) {
      this.setState({ isCharSpecial: true })
    } else {
      this.setState({ isCharSpecial: false })
    }
    if (/\p{Lu}/u.test(value)) {
      this.setState({ isCharUpper: true })
    } else {
      this.setState({ isCharUpper: false })
    }
    if (value !== value.toUpperCase()) {
      this.setState({ isCharLower: true })
    } else {
      this.setState({ isCharLower: false })
    }
    if (value.length >= 8) {
      this.setState({ isCharLength: true })
    } else {
      this.setState({ isCharLength: false })
    }
    this.setState({ passwordNew: value })
  }

  sendRecoveryMail() {
    const header = {
      "Content-Type": configJSON.forgotPasswordAPiContentType,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.recoveryEmailApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.recoveryMailAPiEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    const httpBody = { email: this.state.emailValue };

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpPostMethod
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  validateToken(tokenValue: string) {
    const header = {
      "Content-Type": configJSON.forgotPasswordAPiContentType,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.validateTokenApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.validateTokenAPIEndPoint}?token=${tokenValue}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpGetMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  setNewPassword = () => {
    if (!this.state.passwordNew || !this.state.confirmPasswordNew) {
      toast.error("All field are mandatory")
      return
    }
    if (this.state.passwordNew !== this.state.confirmPasswordNew) {
      toast.error("Password and confirm password not same")
      return
    }
    const header = {
      "Content-Type": configJSON.forgotPasswordAPiContentType,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.resetPasswordApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.validateTokenAPIEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    const httpBody = {
      token: this.state.paramsToken,
      password: this.state.passwordNew,
      confirmation_password: this.state.confirmPasswordNew
    };

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpPostMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  sendMail = (event: React.KeyboardEvent) => {
    if (event.key === 'Enter') {
      this.sendRecoveryMail()
    }
  }

  checkSpace = (event: React.KeyboardEvent) => {
    if (event.key === ' ') {
      event.preventDefault()
    }
    if (event.key === 'Enter') {
      this.setNewPassword()
    }
  }

  changeThemeMode = (event: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({ darkThemeSelected: event.target.checked })
    setStorageData('darkMode', `${event.target.checked}`)
  }
  // Customizable Area End
}
