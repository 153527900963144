import React from "react";

// Customizable Area Start
import {
  Box,
  Typography,
  InputAdornment,
  IconButton,
  styled,
  Switch,
  Link,
  TextField
} from "@material-ui/core";
import { ThemeProvider } from "@material-ui/core/styles";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import Visibility from "@material-ui/icons/Visibility";
import LockIcon from "@material-ui/icons/Lock";
import { lightTheme, darkTheme, NextFormButton, NextButton, ValidationSection, PasswordChecked, PasswordText } from "../../email-account-registration/src/EmailAccountRegistration.web";
import { logoLight, logoDark, productCard } from "../../email-account-registration/src/assets";
import { mailIcon } from "../../email-account-login/src/assets"
import { ToastContainer } from "react-toastify";
import { EditHeading, FieldModal } from "../../customisableuserprofiles/src/CustomisableUserProfiles.web";
import { darkVerify, lightVerify } from "../../postcreation/src/assets";
import { SwitchTheme } from "../../email-account-login/src/EmailAccountLoginBlock.web";
// Customizable Area End

import ForgotPasswordController, {
  Props,
} from "./ForgotPasswordController";


export default class ForgotPassword extends ForgotPasswordController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }
  // Customizable Area Start
  successModal = () => (
    <FieldModal
      data-test-id="mailSuccessModal"
      open={this.state.mailSuccessMsg !== ""}
      onCancel={() => this.setState({ mailSuccessMsg: "", emailValue: "" })}
      centered
      closable={true}
      footer={null}
      destroyOnClose
      width={696}
      style={{ height: '430px' }}
    >
      <div style={{ display: 'flex', justifyContent: "center", padding: '20px', flexDirection: 'column', alignItems: 'center' }}>
        <img src={this.state.darkThemeSelected ? darkVerify : lightVerify} alt="verificationModal" style={{ height: '176px' }} />
        <EditHeading style={{ textAlign: 'center', fontWeight: 700, fontFamily: "poppins", fontSize: '26px', letterSpacing: 0 }}>Password reset link has been sent</EditHeading>
        <SubMessage>{this.state.mailSuccessMsg}</SubMessage>
      </div>
      <NextButton
        data-test-id="btnResend"
        onClick={() => this.sendRecoveryMail()}
        fullWidth
        style={{ color: '#F8FAFC' }}
      >
        Resend
      </NextButton>
    </FieldModal>
  )

  renderForgotPassword = () => (
    <MainCard>
      <Typography
        color="secondary"
        variant="h4"
        component="h2"
        style={{ textAlign: 'center', fontFamily: 'Poppins', fontWeight: 700, fontSize: '24px', lineHeight: '32px' }}
      >
        Password recovery
      </Typography>
      <SubHeading>Forget password? no worries we’ll send a recovery link to your email</SubHeading>
      <Box sx={{ width: "100%", paddingTop: "20px" }}>
        <Box sx={{ padding: "10px 0px" }}>
          <Typography
            color="textPrimary"
            variant="subtitle1"
            component="div"
            style={{ fontFamily: 'Rubik', fontWeight: 400, fontSize: '16px', lineHeight: '24px' }}
          >
            Email address
          </Typography>
          <InputField
            data-test-id="EmailInput"
            id="outlined-error-helper-text"
            error={this.state.emailError !== ""}
            placeholder="Enter your email address"
            fullWidth
            value={this.state.emailValue}
            onChange={(event) => this.setState({ emailValue: event.target.value })}
            onKeyPress={this.sendMail}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <img src={mailIcon} />
                </InputAdornment>
              )
            }}
            helperText={this.state.emailError}
          />
        </Box>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            flexDirection: "column",
            padding: "10px 0px",
          }}
        >
          <NextFormButton
            style={{ width: '100%', textTransform: 'none' }}
            data-test-id="btnSendRecoveryMail"
            variant="contained"
            fullWidth
            onClick={() => this.sendRecoveryMail()}
          >
            Send a recovery link
          </NextFormButton>
        </Box>
      </Box>
    </MainCard>
  )

  renderNewPassword = () => (
    <MainCard>
      <Typography
        color="secondary"
        variant="h4"
        component="h2"
        style={{ textAlign: 'center', fontFamily: 'Poppins', fontWeight: 700, fontSize: '24px', lineHeight: '32px' }}
      >
        New Password
      </Typography>
      <SubHeading>{this.state.newPasswordMsg}</SubHeading>
      <Box sx={{ width: "100%", paddingTop: "20px" }}>
        <Box sx={{ padding: "10px 0px" }}>
          <Typography color="secondary" variant="subtitle1" component="div" style={{ fontFamily: 'Rubik', fontWeight: 400, fontSize: '16px', lineHeight: '24px' }}>
            New Password
          </Typography>
          <InputField
            data-test-id="passwordNew"
            type={this.state.showNewPassword ? "text" : "password"}
            placeholder="Enter your new password"
            fullWidth
            value={this.state.passwordNew}
            onChange={this.changeNewPassword}
            onKeyPress={this.checkSpace}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <IconButton disabled={!this.state.passwordNew}>
                    <LockIcon />
                  </IconButton>
                </InputAdornment>
              ),
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={() => this.setState({ showNewPassword: !this.state.showNewPassword })}
                    edge="end"
                    disabled={!this.state.passwordNew}
                  >
                    {this.state.showNewPassword ? (
                      <Visibility />
                    ) : (
                      <VisibilityOff />
                    )}
                  </IconButton>
                </InputAdornment>
              )
            }}
          />
        </Box>
        <Box sx={{ padding: "10px 0px" }}>
          <Typography color="secondary" variant="subtitle1" component="div" style={{ fontFamily: 'Rubik', fontWeight: 400, fontSize: '16px', lineHeight: '24px' }}>
            Confirm New Password
          </Typography>
          <InputField
            data-test-id="confirmNewPassword"
            type={this.state.showNewConfirmPassword ? "text" : "password"}
            placeholder="Confirm your new password"
            fullWidth
            value={this.state.confirmPasswordNew}
            onChange={(event) => this.setState({ confirmPasswordNew: event.target.value })}
            onKeyPress={this.checkSpace}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <IconButton disabled={!this.state.confirmPasswordNew}>
                    <LockIcon />
                  </IconButton>
                </InputAdornment>
              ),
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={() => this.setState({ showNewConfirmPassword: !this.state.showNewConfirmPassword })}
                    edge="end"
                    disabled={!this.state.confirmPasswordNew}
                  >
                    {this.state.showNewConfirmPassword ? (
                      <Visibility />
                    ) : (
                      <VisibilityOff />
                    )}
                  </IconButton>
                </InputAdornment>
              )
            }}
          />
        </Box>
        {
          !(this.state.isCharLength && this.state.isCharNumber && this.state.isCharLower && this.state.isCharSpecial && this.state.isCharUpper) &&
          <div style={{ margin: '15px' }}>
            <ValidationSection>
              <div>
                <PasswordChecked defaultChecked style={{ backgroundColor: this.state.isCharLength ? '#1A469C' : '#B8C6E0' }} />
                <PasswordText>Use 8 or more characters</PasswordText>
              </div>
              <div>
                <PasswordChecked defaultChecked style={{ backgroundColor: this.state.isCharNumber ? '#1A469C' : '#B8C6E0' }} />
                <PasswordText>One number</PasswordText>
              </div>
              <div>
                <PasswordChecked defaultChecked style={{ backgroundColor: this.state.isCharLower ? '#1A469C' : '#B8C6E0' }} />
                <PasswordText>One lowercase character</PasswordText>
              </div>
            </ValidationSection>
            <ValidationSection>
              <div>
                <PasswordChecked defaultChecked style={{ backgroundColor: this.state.isCharSpecial ? '#1A469C' : '#B8C6E0' }} />
                <PasswordText>One special character</PasswordText>
              </div>
              <div>
                <PasswordChecked defaultChecked style={{ backgroundColor: this.state.isCharUpper ? '#1A469C' : '#B8C6E0' }} />
                <PasswordText>One Uppercase character</PasswordText>
              </div>
            </ValidationSection>
          </div>
        }
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            padding: "10px 0px",
          }}
        >
          <NextFormButton
            style={{ width: '100%', textTransform: 'none' }}
            data-test-id="setPassword"
            variant="contained"
            fullWidth
            onClick={this.setNewPassword}
          >
            Set New Password
          </NextFormButton>
        </Box>
      </Box>
    </MainCard>
  )
  // Customizable Area End
  render() {
    return (
      // Customizable Area Start
      <ThemeProvider theme={this.state.darkThemeSelected ? darkTheme : lightTheme}>
        <LoginContainer>
          <HeaderLogin>
            <img src={this.state.darkThemeSelected ? logoLight : logoDark} alt="logo" />
            <HomeLink href="/">HOME</HomeLink>
          </HeaderLogin>
          <LoginBox>
            <UpperImg src={productCard} />
            {this.state.enableNewPassword ? this.renderNewPassword() : this.renderForgotPassword()}
            <LowerImg src={productCard} />
          </LoginBox>
          <Box style={{ marginTop: '15px' }}>
            <BackText data-test-id="backLogin">Back to</BackText><LoginLink onClick={() => this.goToLogin()}>Login</LoginLink>
          </Box>

          <SwitchTheme>
            <Switch data-test-id="changeTheme" checked={this.state.darkThemeSelected} onChange={this.changeThemeMode} />
            <Typography>Dark Theme</Typography>
          </SwitchTheme>
        </LoginContainer>
        {this.successModal()}
        <ToastContainer />
      </ThemeProvider>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
const LoginContainer = styled(Box)(({ theme }) => ({
  height: '100vh',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  flexDirection: 'column',
  background: theme.palette.background.default,
  '& .MuiPaper-root': {
    backgroundColor: theme.palette.primary.main
  }
}))

const HeaderLogin = styled(Box)({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
  width: '1000px'
})

const SubHeading = styled(Typography)(({ theme }) => ({
  fontFamily: 'Rubik',
  fontSize: '18px',
  fontWeight: 400,
  lineHeight: '26px',
  color: theme.palette.text.primary,
  textAlign: "center"
}))

const HomeLink = styled(Link)(({ theme }) => ({
  fontSize: '24px',
  fontWeight: 700,
  color: theme.palette.info.contrastText,
  fontFamily: 'Rubik',
  letterSpacing: '-0.005em',
  lineHeight: '32px',
}))

const LoginBox = styled(Box)({
  width: '900px',
  alignItems: 'center',
  justifyContent: 'center',
  display: 'flex',
  flexDirection: 'column',
})
const UpperImg = styled('img')({
  alignSelf: 'flex-end',
  transform: 'rotate(-10deg)',
  top: -20,
  position: 'fixed',
  opacity: '0.5',
  gap: '0px',
  height: '303.72px',
  width: '276.49px',
})

const LowerImg = styled('img')({
  bottom: 60,
  gap: '0px',
  position: 'fixed',
  transform: 'rotate(-180deg)',
  opacity: '0.5',
  alignSelf: 'flex-start',
  height: '303.72px',
  width: '276.49px',
})

const MainCard = styled(Box)(({ theme }) => ({
  zIndex: 1,
  padding: '28px',
  background: theme.palette.primary.main,
  borderRadius: '30px',
  gap: '28px',
  width: '696px',
  boxShadow: '0px 2px 8px 0px #00000014',
  '& .MuiInput-underline::after': {
    borderBottom: 'transparent'
  },
  '& .MuiInput-underline::before': {
    borderBottom: 'transparent'
  },
  '& .MuiInput-underline:hover:not(.Mui-disabled)::before': {
    borderBottom: 'transparent'
  },
  '& .MuiButton-contained:hover': {
    backgroundColor: theme.palette.action.active,
    boxShadow: "none"
  },
  '& .MuiButton-root:hover': {
    backgroundColor: theme.palette.action.active
  }
}))

const InputField = styled(TextField)(({ theme }) => ({
  width: '100%',
  gap: '8px',
  fontFamily: 'Rubik',
  fontSize: '16px',
  fontWeight: 500,
  lineHeight: '24px',
  textAlign: 'left',
  marginTop: '5px',
  background: theme.palette.background.default,
  '& .MuiInput-underline:hover:not(.Mui-disabled)::before': {
    borderBottom: 'transparent'
  },
  '& .MuiInput-underline::after': {
    borderBottom: 'transparent'
  },
  '& .MuiSvgIcon-root': {
    fill: theme.palette.info.contrastText
  },
  '& .MuiInputBase-root': {
    border: `1px solid ${theme.palette.info.dark}`,
    padding: '16px',
    borderRadius: '8px',
    color: theme.palette.info.main,
  },
  '& .MuiFormHelperText-root.Mui-error': {
    bottom: 10,
    position: 'relative',
    color: '#B00606'
  }
}))

const BackText = styled('span')(({ theme }) => ({
  fontFamily: 'Rubik',
  fontSize: '18px',
  fontWeight: 400,
  lineHeight: '26px',
  textAlign: 'center',
  color: theme.palette.info.main,
}))

const LoginLink = styled('span')(({ theme }) => ({
  fontSize: "18px",
  lineHeight: '26px',
  fontFamily: 'Rubik',
  color: theme.palette.info.contrastText,
  fontWeight: 500,
  marginTop: "10px",
  textDecoration: 'underline',
  cursor: 'pointer',
  marginLeft: '5px'
}))

const SubMessage = styled(Typography)(({ theme }) => ({
  fontSize: "18px",
  lineHeight: '26px',
  fontFamily: 'Rubik',
  color: theme.palette.text.primary,
  fontWeight: 400,
  textAlign: 'center'
}))
// Customizable Area End