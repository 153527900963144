import React from "react";

// Customizable Area Start
import {
  Container,
  Box,
  Button,
  Typography,
  Grid,
  Avatar,
  ButtonGroup,
  Badge,
  IconButton,
  Menu,
  MenuItem,
  Input,
  InputAdornment
} from "@material-ui/core";
import MoreVertOutlinedIcon from '@material-ui/icons/MoreVertOutlined';
import { styled, ThemeProvider } from "@material-ui/core/styles";
import { imgBell, notify_button, doneAll, deleteIcon } from "./assets";
import Loader from "../../../components/src/Loader.web";
import HamburgerMenu from "../../hamburgermenu/src/HamburgerMenu.web";
import { darkTheme, lightTheme } from "../../email-account-registration/src/EmailAccountRegistration.web";
import { Pagination } from "@material-ui/lab";
import { FieldModal, ModalHeading, ModalLine, ModalSubHead } from "../../customisableuserprofiles/src/CustomisableUserProfiles.web";
import AttachmentOutlinedIcon from '@material-ui/icons/AttachmentOutlined';
import SendOutlinedIcon from '@material-ui/icons/SendOutlined';
// Customizable Area End

import NotificationsController, {
  Props,
  configJSON,
} from "./NotificationsController";

export default class Notifications extends NotificationsController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  CardSection = () => (
    <CardHeader>
      <div style={{ display: 'flex', alignItems: 'center', marginLeft: 10 }}>
        <Badge color='secondary' variant="dot" anchorOrigin={{ vertical: 'top', horizontal: 'left' }}>
          <Avatar alt="Remy Sharp" />
        </Badge>
        <div style={{ marginLeft: '10px' }}>
          <NameField>Ibrahim M.</NameField>
          <DesignationField>Instructor</DesignationField>
        </div>
      </div>
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <CourseTitle>Yesterday at 4:54 PM</CourseTitle>
        <DetailsButton variant="text" data-test-id="ReplyButton" onClick={this.openReplyModal}>Reply</DetailsButton>
        <MoreVertOutlinedIcon />
      </div>
    </CardHeader>
  )

  CommentCard = () => (
    <CommentMessageCard>
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <Avatar alt="Remy Sharp" src="" />
        <div style={{ marginLeft: '10px' }}>
          <NameField>Ibrahim M.</NameField>
          <DesignationField>Instructor</DesignationField>
        </div>
      </div>
      <CourseValue>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.</CourseValue>
      <CourseTitle style={{ textAlign: 'right' }}>Yesterday at 4:54 PM</CourseTitle>
    </CommentMessageCard>
  )

  ReplyModal = () => (
    <FieldModal
      data-test-id='replyModal'
      open={this.state.replyModal}
      onCancel={this.closeReplyModal}
      title={
        <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
          <ModalHeading variant="h5">Message Notification</ModalHeading>
          <ModalLine style={{ width: '15%' }} />
          <ModalSubHead variant="subtitle2">Reply to Message</ModalSubHead>
          <ModalLine style={{ width: '15%' }} />
        </div>}
      centered
      closable={true}
      footer={null}
      destroyOnClose
      width={772}
      style={{ paddingBottom: '40px' }}
    >
      <div className="scrollModal">
        {this.CommentCard()}
        {this.CommentCard()}
        {this.CommentCard()}
      </div>
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <CommentField
          data-test-id="replyText"
          placeholder="Type your reply"
          fullWidth
          value={this.state.commentMessage}
          onChange={this.changeCommentMessage}
          endAdornment={
            <InputAdornment position="end">
              <IconButton
                aria-label="toggle password visibility"
                edge="end"
              >
                <AttachmentOutlinedIcon />
              </IconButton>
            </InputAdornment>
          }
        />
        <SendOutlinedIcon />
      </div>
    </FieldModal>
  )
  // Customizable Area End

  render() {
    return (
      // Customizable Area Start
      <ThemeProvider theme={this.state.darkThemeStatus ? darkTheme : lightTheme}>
        <Loader loading={false} />
        <HamburgerMenu
          navigation={this.props.navigation}
          handleMenu={this.toggleSideMenu}
          open={this.state.menuStatus}
          themeDark={this.state.darkThemeStatus}
          handleTheme={this.toggleDarkTheme}
        />
        <MainContainer style={{ paddingLeft: this.state.menuStatus ? 273 : 130 }}>
          <HeadDiv >
            <BodyHeader>
              <NotificationTitle>{configJSON.notifications}</NotificationTitle>
              <div>
                <ButtonGroup>
                  <Button
                    data-test-id='showAllNotification'
                    variant="text"
                    onClick={() => this.showUnreadNotification(false)}
                    className={!this.state.unread ? "selectedButton" : ""}
                  >
                    All
                  </Button>
                  <Button
                    data-test-id='showUnreadNotification'
                    variant="text"
                    onClick={() => this.showUnreadNotification(true)}
                    className={this.state.unread ? "selectedButton" : ""}
                  >
                    Unread
                  </Button>
                </ButtonGroup>
                <NotificationSettingsButton data-test-id="gotoSettings" startIcon={<img src={notify_button} />} onClick={this.goToNotificationSettings}>
                  Notification Settings
                </NotificationSettingsButton>
              </div>
            </BodyHeader>
            <Grid container>
              <Grid item xs={6}>
                {this.state.data?.map((item) => (
                  <CardSection>
                    <CardHeader>
                      <div style={{ display: 'flex', alignItems: 'center', marginLeft: '10px' }}>
                        <Badge invisible={item.attributes.is_read} color='secondary' variant="dot" anchorOrigin={{ vertical: 'top', horizontal: 'left' }}>
                          <Avatar alt="Sharp" src={item.attributes.created_by?.data?.attributes?.image} />
                        </Badge>
                        <div style={{ marginLeft: '10px' }}>
                          <NameField>{item.attributes.created_by?.data?.attributes?.first_name || "Ibrahim"}</NameField>
                          <DesignationField>{item.attributes.created_by?.data?.attributes?.role_id || "Educator"}</DesignationField>
                        </div>
                      </div>
                      <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end' }}>
                        <CourseTitle>{this.timeSince(item.attributes.created_at)}</CourseTitle>
                        <DotSection>
                          <IconButton
                            aria-label="account of current user"
                            aria-controls="menu-appbar"
                            aria-haspopup="true"
                            onClick={(event) => this.handleOpenNavMenu(event, item.attributes.id)}
                            color="inherit"
                            data-test-id="openOption"
                          >
                            <MoreVertOutlinedIcon />
                          </IconButton>
                          <Menu
                            id="menu-appbar"
                            anchorEl={this.state.anchorElNav}
                            anchorOrigin={{
                              vertical: 'bottom',
                              horizontal: 'right',
                            }}
                            keepMounted
                            transformOrigin={{
                              vertical: 'top',
                              horizontal: 'right',
                            }}
                            open={Boolean(this.state.anchorElNav)}
                            onClose={this.handleCloseNavMenu}
                            elevation={0}
                            style={{ top: 30 }}
                          >
                            <MenuItem data-test-id='markAsRead' onClick={() => this.markAsRead(item.attributes.id)}>
                              <img height={16} width={16} src={doneAll} style={{ marginRight: 10 }} />
                              <Typography style={{ fontSize: 12, color: '#1A469C' }}>{configJSON.markAsReadText}</Typography>
                            </MenuItem>

                            <MenuItem data-test-id='deleteNotification' onClick={() => this.deleteNotifications(item.attributes.id)}>
                              <img height={16} width={16} src={deleteIcon} style={{ marginRight: 10 }} />
                              <Typography style={{ fontSize: 12, color: '#DC2626' }}>{configJSON.deleteText}</Typography>
                            </MenuItem>
                          </Menu>
                        </DotSection>
                      </div>
                    </CardHeader>
                    <div style={{ display: 'flex', flexDirection: 'row' }}>
                      <div style={{ width: '50%', margin: '10px 5px 0px 0px' }}>
                        <CourseTitle>{item.attributes.headings}</CourseTitle>
                        <CourseValue>{item.attributes.contents}</CourseValue>
                      </div>
                      <CourseSection style={{ width: '50%', display: 'flex', flexDirection: 'row', padding: '0px', height: '100%', maxHeight: '55px', alignItems: 'center' }}>
                        <img src={imgBell} alt="" style={{ width: '50%', height: '100%', maxHeight: '55px' }} />
                        <div style={{ width: '50%' }}>
                          <CourseTitle>Course Name</CourseTitle>
                          <CourseValue>UI/UX principles</CourseValue>
                        </div>
                      </CourseSection>
                    </div>
                  </CardSection>
                ))}
              </Grid>
              <Grid item xs={6}>
                <CardSection>
                  {this.CardSection()}
                  <CourseSection>
                    <CourseTitle>Comment Left on the community post</CourseTitle>
                    <CourseValue>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris </CourseValue>
                  </CourseSection>
                </CardSection>
                <CardSection>
                  {this.CardSection()}
                  <div style={{ display: 'flex', flexDirection: 'row' }}>
                    <div style={{ width: '50%', margin: '15px 5px 0px 0px' }}>
                      <CourseTitle>Team Learning Goal</CourseTitle>
                      <CourseValue>Manager just assigned new learning goal to be achieved</CourseValue>
                    </div>
                    <GoalRightSection>
                      <NameField>Achieving highest score</NameField>
                      <CourseTitle>Course Name</CourseTitle>
                    </GoalRightSection>
                  </div>
                </CardSection>
              </Grid>
            </Grid>
            <Pagination
              count={this.state.totalPage}
              data-test-id="changePageNumber"
              onChange={this.changePageNumber}
              page={this.state.currentPage}
              size="small"
            />
          </HeadDiv>
          {this.state.replyModal && this.ReplyModal()}
        </MainContainer>
      </ThemeProvider >
      // Customizable Area End
    );
  }
}

// Customizable Area Start
export const MainContainer = styled(Box)(({ theme }) => ({
  width: '100%',
  height: '100%',
  background: theme.palette.background.default
}))

export const HeadDiv = styled(Container)(({ theme }) => ({
  background: theme.palette.action.focus,
  paddingRight: 48,
  transition: 'all 300ms linear',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  width: '100%',
  maxWidth: '1070px',
  borderRadius: '18px',
  margin: '20px 0px',
  "& .MuiButtonGroup-root": {
    borderRadius: '50px',
    background: theme.palette.background.default
  },
  "& .MuiButtonGroup-grouped": {
    textTransform: 'capitalize',
    fontWeight: 500,
    fontsize: '14px',
    lineHeight: '22px',
    color: theme.palette.info.light,
    borderRadius: '30px',
    padding: '5px',
    margin: '5px'
  },
  "& .selectedButton": {
    color: theme.palette.info.contrastText,
    background: theme.palette.background.paper
  },
  "& .MuiPaginationItem-page.Mui-selected": {
    background: "#1A469C",
    color: "#FFF",
  },
  "& .MuiPaginationItem-root": {
    fontFamily: "Rubik",
    background: "#F1F5F9",
    fontWeight: 500,
    lineHeight: "18px",
    fontSize: "10px",
    margin: "0px 4px"
  },
  "& .MuiPagination-ul": {
    background: theme.palette.background.default,
    padding: '5px',
    borderRadius: '40px'
  }
}))

const CourseTitle = styled(Typography)(({ theme }) => ({
  fontWeight: 400,
  fontSize: '10px',
  color: theme.palette.info.light
}))

const CourseValue = styled(Typography)(({ theme }) => ({
  fontWeight: 400,
  fontSize: '12px',
  color: theme.palette.info.main
}))

export const NameField = styled(Typography)(({ theme }) => ({
  fontWeight: 700,
  fontSize: '12px',
  lineHeight: '18px',
  color: theme.palette.info.main
}))

export const DesignationField = styled(Typography)(({ theme }) => ({
  fontWeight: 700,
  fontSize: '11.51px',
  lineHeight: '14.8px',
  color: theme.palette.secondary.light
}))

const DetailsButton = styled(Button)(({ theme }) => ({
  maxWidth: '81px',
  height: '32px',
  padding: '10px 16px',
  borderRadius: '30px',
  fontFamily: 'Rubik',
  fontSize: '14px',
  fontWeight: 700,
  lineHeight: '22px',
  textAlign: 'left',
  color: theme.palette.info.contrastText,
  textTransform: 'capitalize',
  marginLeft: '10px'
}))

const CourseSection = styled(Box)(({ theme }) => ({
  borderRadius: '10px',
  padding: '14.8px',
  border: `1px solid ${theme.palette.text.hint}`,
  display: 'flex',
  flexDirection: 'column',
  marginTop: '10px',
}))

const NotificationSettingsButton = styled(Button)(({ theme }) => ({
  maxHeight: '189px',
  height: '24px',
  fontSize: '16px',
  fontWeight: 500,
  lineHeight: '24px',
  textTransform: 'capitalize',
  color: theme.palette.info.contrastText
}))

const NotificationTitle = styled(Typography)(({ theme }) => ({
  fontWeight: 500,
  fontSize: '30px',
  lineHeight: '40px',
  color: theme.palette.info.main
}))

const CardSection = styled(Box)(({ theme }) => ({
  background: theme.palette.background.default,
  padding: '12px 16px',
  borderRadius: '18px',
  margin: '5px'
}))

const CardHeader = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
  alignItems: 'center',
  "& .MuiBadge-colorSecondary": {
    backgroundColor: '#DC2626'
  },
  "& .MuiSvgIcon-root": {
    width: '18px',
    fill: theme.palette.secondary.main,
  },
}))

const BodyHeader = styled(Box)({
  display: 'flex',
  justifyContent: 'space-between',
  width: '100%',
  margin: '10px',
  "& .MuiBadge-colorSecondary": {
    backgroundColor: '#DC2626'
  }
})

export const DotSection = styled(Box)(({ theme }) => ({
  display: 'flex',
  "& .MuiMenu-paper": {
    height: '80px',
    borderRadius: '18px',
    background: theme.palette.background.default
  },
  "& .MuiSvgIcon-root": {
    fill: `${theme.palette.secondary.main} !important`,
    width: '18px'
  },
  "& .MuiIconButton-root": {
    paddingRight: 0
  }
}))

const GoalRightSection = styled(Box)(({ theme }) => ({
  background: theme.palette.action.focus,
  borderRadius: '6px',
  borderLeft: `4px solid ${theme.palette.action.selected}`,
  width: '50%',
  padding: '10px',
  marginTop: '15px'
}))

const CommentMessageCard = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  marginLeft: 10,
  background: theme.palette.primary.main,
  borderRadius: '14px',
  padding: '12px 24px'
}))

const CommentField = styled(Input)(({ theme }) => ({
  height: '44px',
  padding: '10px 24px',
  borderRadius: '8px',
  border: `1px solid ${theme.palette.primary.main}`,
  fontFamily: 'Rubik',
  fontSize: '16px',
  fontWeight: 400,
  lineHeight: '24px',
  textAlign: 'left',
  background: theme.palette.background.default,
  margin: '10px 10px 0px 0px',
  color: theme.palette.info.main,
  "& .MuiInputBase-input": {
    border: 'none',
    padding: "10px 12px 10px 0px"
  },
  '& .MuiSvgIcon-root': {
    fill: theme.palette.info.dark
  }
}))
// Customizable Area End
